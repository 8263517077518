import {
  CallState,
  FetchFlightTripDetailsResponse,
  FlightShopStep,
  ShopFlightsResponse,
} from "redmond";
import * as actionTypes from "./constants";
import * as H from "history";

export interface IFetchPackagesFlightShop {
  type: actionTypes.FETCH_PACKAGE_FLIGHT_SHOP;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallPackageFlightAvailability?: boolean;
  };
}

export const fetchPackagesFlightShop = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallPackageFlightAvailability?: boolean;
  }
): IFetchPackagesFlightShop => ({
  type: actionTypes.FETCH_PACKAGE_FLIGHT_SHOP,
  history,
  options,
});

export interface ISetPackagesFlightShopResults {
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS;
  flightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  flightShopResults: ShopFlightsResponse;
}

export const setPackagesFlightShopResults = (args: {
  flightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  flightShopResults: ShopFlightsResponse;
}): ISetPackagesFlightShopResults => ({
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS,
  flightShopCallState: args.flightShopCallState,
  flightShopResults: args.flightShopResults,
});

export interface ISetPackagesFlightShopCallStateFailed {
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED;
  flightShopCallError: any; // Change this to correct error type
}

export const setPackagesFlightShopCallStateFailed = (
  flightShopCallError: any // Change this to correct error type
): ISetPackagesFlightShopCallStateFailed => ({
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED,
  flightShopCallError,
});

export interface IFetchPackagesTripDetails {
  type: actionTypes.FETCH_PACKAGES_TRIP_DETAILS;
  history: H.History;
  tripId: string;
}

export const fetchPackagesTripDetails = (
  history: H.History,
  tripId: string
): IFetchPackagesTripDetails => ({
  type: actionTypes.FETCH_PACKAGES_TRIP_DETAILS,
  history,
  tripId,
});

export interface ISetPackagesTripDetails {
  type: actionTypes.SET_PACKAGES_TRIP_DETAILS;
  tripDetailsResponse: FetchFlightTripDetailsResponse;
}

export const setPackagesTripDetails = (
  tripDetailsResponse: FetchFlightTripDetailsResponse
): ISetPackagesTripDetails => ({
  type: actionTypes.SET_PACKAGES_TRIP_DETAILS,
  tripDetailsResponse: tripDetailsResponse,
});

export interface ISetPackagesTripDetailsCallStateFailed {
  type: actionTypes.SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED;
  tripDetailsCallError: any; // Change this to correct error type
}

export const setPackagesTripDetailsCallStateFailed = (
  tripDetailsCallError: any // Change this to correct error type
): ISetPackagesTripDetailsCallStateFailed => ({
  type: actionTypes.SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED,
  tripDetailsCallError,
});

export interface ISetPackagesChosenOutgoingSlice {
  type: actionTypes.SET_PACKAGES_CHOSEN_OUTGOING_SLICE;
  outgoingSliceId: string;
  outgoingFareId: string;
  tripId: string;
  outgoingFareRating?: number;
  resetReturnIds?: boolean;
}

export const setPackagesChosenOutgoingSlice = (
  outgoingSliceId: string,
  outgoingFareId: string,
  tripId: string,
  outgoingFareRating?: number,
  resetReturnIds?: boolean
): ISetPackagesChosenOutgoingSlice => ({
  type: actionTypes.SET_PACKAGES_CHOSEN_OUTGOING_SLICE,
  outgoingSliceId,
  outgoingFareId,
  tripId,
  outgoingFareRating,
  resetReturnIds,
});

export interface ISetPackagesChosenReturnSlice {
  type: actionTypes.SET_PACKAGES_CHOSEN_RETURN_SLICE;
  tripId: string;
  returnFareId: string;
  returnSliceId: string;
  returnFareRating?: number;
}

export const setPackagesChosenReturnSlice = (
  tripId: string,
  returnSliceId: string,
  returnFareId: string,
  returnFareRating?: number
): ISetPackagesChosenReturnSlice => ({
  type: actionTypes.SET_PACKAGES_CHOSEN_RETURN_SLICE,
  returnFareId,
  returnSliceId,
  returnFareRating,
  tripId,
});

export interface ISetPackagesFlightShopProgress {
  type: actionTypes.SET_PACKAGES_FLIGHT_SHOP_PROGRESS;
  progress: FlightShopStep;
}

export const setPackagesFlightShopProgress = (
  progress: FlightShopStep
): ISetPackagesFlightShopProgress => ({
  type: actionTypes.SET_PACKAGES_FLIGHT_SHOP_PROGRESS,
  progress,
});

export type PackagesFlightShopActions =
  | IFetchPackagesFlightShop
  | ISetPackagesFlightShopResults
  | ISetPackagesFlightShopCallStateFailed
  | IFetchPackagesTripDetails
  | ISetPackagesTripDetails
  | ISetPackagesTripDetailsCallStateFailed
  | ISetPackagesChosenOutgoingSlice
  | ISetPackagesChosenReturnSlice
  | ISetPackagesFlightShopProgress;
