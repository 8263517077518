import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { DesktopPackagesBookWorkflow } from "./component";
import { IStoreState } from "../../../../reducers/types";

export const mapStateToProps = (_: IStoreState) => {
  return {};
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopPackagesBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopPackagesBookWorkflow = withRouter(
  connector(DesktopPackagesBookWorkflow)
);
