import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PackagesShopProgressBar } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesShopProgressBarConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackagesShopProgressBar = connector(
  withRouter(PackagesShopProgressBar)
);
