import { Box } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { CallState, SELECT_HOTEL_ROOM } from "redmond";
import { useExperiments } from "../../context/experiments";
import { PackageHotelShopConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import queryStringParser from "query-string";
import {
  TrackingEventControlType,
  useDeviceTypes,
  useTrackingEventControl,
} from "halifax";
import { DesktopShop } from "./components/DesktopShop";
import { MobileShop } from "./components/MobileShop";
import { PORTAL_TITLE, SELECT_ROOM_TITLE } from "../../lang/textConstants";
import { PATH_FLIGHT_SHOP } from "../../utils/paths";

export interface IPackageHotelShopProps
  extends PackageHotelShopConnectorProps,
    RouteComponentProps {}

export const PackageHotelShop = (props: IPackageHotelShopProps) => {
  const {
    fetchPackageHotelShop,
    history,
    // hotelShopCallState,
    selectedLodging,
    fetchTravelWalletDetails,
    listPaymentMethods,
  } = props;
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const [isReadyToRedirect, setIsReadyToRedirect] = useState<boolean>(false);
  const {
    trackingEventControl,
    updateTrackingEventControl,
    removeEventFromTrackingEventControl,
  } = useTrackingEventControl();
  const expState = useExperiments();

  const queryString = useMemo(
    () => queryStringParser.parse(history.location.search),
    [history.location.search]
  );

  const handleReadyToRedirect = (eventControl?: TrackingEventControlType) => {
    // note: it needs to wait for `selectRoomType` to be executed before proceeding with the page redirect
    setTimeout(() => {
      if (eventControl) {
        updateTrackingEventControl(eventControl);
      }
      setIsReadyToRedirect(true);
    });
  };

  useEffect(() => {
    if (isReadyToRedirect) {
      // delayed firing of SELECT_HOTEL_ROOM
      if (trackingEventControl[SELECT_HOTEL_ROOM]) {
        // trackEvent({
        //   eventName: SELECT_HOTEL_ROOM,
        //   properties: {
        //     ...trackingEventControl[SELECT_HOTEL_ROOM].properties,

        //   },
        // });
        removeEventFromTrackingEventControl(SELECT_HOTEL_ROOM);
      }

      history.push(PATH_FLIGHT_SHOP);
      setIsReadyToRedirect(false);
    }
  }, [history, isReadyToRedirect, trackingEventControl]);

  useEffect(() => {
    if (
      [CallState.Success, CallState.Failed].includes(expState.callState) ||
      expState.experiments.length > 0
    ) {
      fetchPackageHotelShop(history, {
        overrideStateByQueryParams: true,
      });
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  }, [expState.callState, queryString.lodgingId]);

  useEffect(() => {
    if (selectedLodging) {
      document.title = selectedLodging.lodging.name;
    }
  }, [selectedLodging]);

  useEffect(() => {
    fetchTravelWalletDetails();
    listPaymentMethods();
    document.title = SELECT_ROOM_TITLE;
    setTimeout(() => window.scrollTo(0, 0), 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  return (
    <Box className={"package-hotel-shop-root"}>
      {matchesDesktop && (
        <DesktopShop handleReadyToRedirect={handleReadyToRedirect} />
      )}
      {matchesMobile && (
        <MobileShop handleReadyToRedirect={handleReadyToRedirect} />
      )}
    </Box>
  );
};
