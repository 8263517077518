import { checkoutServices } from "./common";
import {
  CartQuoteServices,
  CartUpdateServices,
  CartFulfillServices,
  FlightServices,
  getObjectKeysAsObject,
  FlightPassengerServices,
  AirPFExerciseServices,
  SeatServices,
  ContactInformationServices,
  PassportServices,
  CardPaymentServices,
} from "@capone/checkout";

export const Services: any = {
  ...CartFulfillServices,
  ...CartQuoteServices,
  ...CartUpdateServices,
  ...checkoutServices,
  ...FlightServices,
  ...ContactInformationServices,
  ...FlightPassengerServices,
  ...PassportServices,
  ...AirPFExerciseServices,
  ...SeatServices,
  ...CardPaymentServices,
};

export const ServiceTypes = getObjectKeysAsObject(Services);
