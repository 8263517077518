import { combineReducers } from "@reduxjs/toolkit";
import { reducer as packagesRewards } from "../modules/rewards/reducer";
import { reducer as packagesSearch } from "../modules/search/reducer";
import { reducer as packagesAvailability } from "../modules/availability/reducer";
import { reducer as packageHotelShop } from "../modules/hotel-shop/reducer";
import { reducer as packagesTravelWallet } from "../modules/travel-wallet/reducer";
import { reducer as packagesFlightShop } from "../modules/flight-shop/reducer";
export const rootReducer = combineReducers({
  packagesRewards,
  packagesSearch,
  packagesAvailability,
  packageHotelShop,
  packagesTravelWallet,
  packagesFlightShop,
});
