import {
  IPerson,
  ISelectedTrip,
  PersonId,
  SessionInfo,
  TripDetails,
} from "redmond";
import {
  IContactInfo,
  ParentState,
  getCartInitialContext,
  getAirChfarInitialContext,
  getIntialWalletContext,
} from "@capone/checkout";
import { PackagesMachineContext } from "./types";

export const getInitialPackagesMachineContext =
  function (): PackagesMachineContext {
    return {
      ...getCartInitialContext(),
      sessionInfo: {} as SessionInfo,
      flightSearch: {
        tripCategory: undefined,
        destination: null,
        origin: null,
        departureDate: null,
        returnDate: null,
        originCountryCode: "",
        destinationCountryCode: "",
      },
      flightShop: {
        // TODO: remove when we have real trip details to pull from
        tripDetails: {
          airports: [],
          pointOfSaleCountryCode: "US",
          fareDetails: [
            {
              penaltiesInfo: {
                changePenalty: {
                  Penalty: "Unknown",
                },
                cancelPenalty: {
                  Penalty: "NotPossible",
                },
                PenaltiesInfo: "Known",
              },
              refundable: false,
              paxPricings: [
                {
                  paxType: "ADT",
                  pricing: {
                    baseAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 148,
                      },
                      accountSpecific: {
                        "t.51668116926d4680afe0461879fea70a": {
                          currency: "Miles",
                          value: 14800,
                          currencyDescription: "miles",
                        },
                      },
                      rewards: {
                        "t.51668116926d4680afe0461879fea70a": {
                          currency: "Miles",
                          value: 14800,
                          currencyDescription: "miles",
                        },
                      },
                    },
                    taxAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 89.25,
                      },
                      accountSpecific: {
                        "t.51668116926d4680afe0461879fea70a": {
                          currency: "Miles",
                          value: 8925,
                          currencyDescription: "miles",
                        },
                      },
                      rewards: {
                        "t.51668116926d4680afe0461879fea70a": {
                          currency: "Miles",
                          value: 8925,
                          currencyDescription: "miles",
                        },
                      },
                    },
                    lastTicketDate: "2024-09-04",
                    total: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 237.25,
                      },
                      accountSpecific: {
                        "t.51668116926d4680afe0461879fea70a": {
                          currency: "Miles",
                          value: 23725,
                          currencyDescription: "miles",
                        },
                      },
                      rewards: {
                        "t.51668116926d4680afe0461879fea70a": {
                          currency: "Miles",
                          value: 23725,
                          currencyDescription: "miles",
                        },
                      },
                    },
                  },
                  quantity: 1,
                },
              ],
              timestamp: 1725372029865,
              took: 5154,
              id: "0e119044-a8f8-49d4-ab0f-37ac01973baa",
              conversationId: "7406e7e6-beb3-481f-ad96-e1ba13d055cc",
              multiTicketType: "single",
              slices: [
                {
                  fareShelf: {
                    rating: 0,
                    brandName: "Ultrabasic",
                    shortBrandName: "Ultrabasic",
                    opaqueMapperStep: "bookingCode",
                  },
                  outgoing: true,
                  amenitiesUtas: {
                    amenities: {
                      seat: {
                        flatness: "not_flat",
                        info: {
                          displayText: '30" seat pitch',
                          quality: "na",
                        },
                        pitch: "30",
                        legroom: "standard",
                        width: "standard",
                      },
                      aircraft: {
                        info: {
                          displayText: "737 (narrowbody)",
                          quality: "na",
                        },
                      },
                      entertainment: {
                        info: {
                          displayText: "Streaming entertainment",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                      beverage: {
                        info: {
                          displayText: "Alcohol (fee) & beverages provided",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                      wifi: {
                        info: {
                          displayText: "Basic web browsing (fee)",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                      layout: {
                        info: {
                          displayText: "3-3 seat layout",
                          quality: "na",
                        },
                      },
                      power: {
                        info: {
                          displayText: "Power & USB outlets",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                      freshFood: {
                        info: {
                          displayText: "Light meal (fee)",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                    },
                    utas: {
                      fare: {
                        brandName: "ULTRABASIC",
                        brandCodes: ["BASIC"],
                      },
                      utas: [
                        {
                          headline: "Standard",
                          description: "Standard boarding",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/1d4b32be-5e44-405b-8ad2-7036a516e8e3/small/boarding-priority.png?channel_id=gyr8kszn",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/1d4b32be-5e44-405b-8ad2-7036a516e8e3/large/boarding-priority.png?channel_id=gyr8kszn",
                          },
                          bagLimits: {},
                          category: "boarding-priority",
                          assessment: "restriction",
                        },
                        {
                          headline: "1 personal item",
                          description: "1 personal item",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/07d977fd-089e-47ce-88a6-c0b092a357df/small/carry-on-allowance.png",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/07d977fd-089e-47ce-88a6-c0b092a357df/large/carry-on-allowance.png",
                          },
                          bagLimits: {},
                          category: "carry-on-allowance",
                          assessment: "restriction",
                        },
                        {
                          headline: "Standard",
                          description: "Standard check-in priority",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/9534e76e-c360-4238-8429-a60c30b34fb7/small/check-in-priority.png?channel_id=gyr8kszn",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/9534e76e-c360-4238-8429-a60c30b34fb7/large/check-in-priority.png?channel_id=gyr8kszn",
                          },
                          bagLimits: {},
                          category: "check-in-priority",
                          assessment: "restriction",
                        },
                        {
                          headline: "1st for USD48  - 2nd for USD62",
                          description:
                            "First checked bag for USD48.17 and Second checked bag for USD62.99 per passenger",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/6b105b08-49e0-44d1-92bd-18a28bb9bb00/small/checked-bag-allowance.png",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/6b105b08-49e0-44d1-92bd-18a28bb9bb00/large/checked-bag-allowance.png",
                          },
                          bagLimits: {
                            weightKg: "23",
                            sizeLcm: "158",
                          },
                          category: "checked-bag-allowance",
                          assessment: "fee",
                        },
                        {
                          headline: "Not allowed",
                          description: "Lounge access not allowed",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/376f76bb-d432-4f63-ae51-54fd2034156c/small/lounge-access.png?channel_id=gyr8kszn",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/376f76bb-d432-4f63-ae51-54fd2034156c/large/lounge-access.png?channel_id=gyr8kszn",
                          },
                          bagLimits: {},
                          category: "lounge-access",
                          assessment: "restriction",
                        },
                        {
                          headline: "Assigned or for a fee",
                          description:
                            "Seat assigned or select anytime for a fee",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/5f555793-30c5-4fa3-b1af-1f984ab9dc1c/small/Seat-Selection.png?channel_id=gyr8kszn",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/5f555793-30c5-4fa3-b1af-1f984ab9dc1c/large/Seat-Selection.png?channel_id=gyr8kszn",
                          },
                          bagLimits: {},
                          category: "seat-selection",
                          assessment: "fee",
                        },
                        {
                          headline: "Free",
                          description: "Change allowed for free",
                          bagLimits: {},
                          category: "advance-change",
                          assessment: "benefit",
                        },
                      ],
                      changePolicy: {
                        anytime: {
                          headline: "Free",
                          description: "Change allowed for free",
                          assessment: "Benefit",
                        },
                      },
                      cancellationPolicy: {
                        afterDeparture: {
                          headline: "Non-refundable",
                          description: "Non-refundable",
                          assessment: "Restriction",
                        },
                        beforeDeparture: {
                          headline: "Non-refundable",
                          description: "Non-refundable",
                          assessment: "Restriction",
                        },
                      },
                    },
                    upas: [
                      {
                        headline: "Recharge during your WestJet experience",
                        description:
                          'Charge all your devices before you reach your destination with AC power outlets and USB ports at each Economy 31" / 79cm seat.',
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/4f93d0b1-9b47-4850-95bb-2324fa034c5f/small/Seat.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/4f93d0b1-9b47-4850-95bb-2324fa034c5f/large/Seat.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Inflight food and beverages",
                        description:
                          "Choose from a selection of snacks and beverages. Our food service and menu offering vary by cabin, aircraft and flight duration.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/1c80bc11-ea80-4aa1-aa6a-a7e4d04879eb/small/Snack.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/1c80bc11-ea80-4aa1-aa6a-a7e4d04879eb/large/Snack.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Get your head in the clouds",
                        description:
                          "Relax and enjoy hundreds of hours of movies and TV episodes with WestJet Connect.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/d198bf15-af1c-4e7e-bac7-2da332cbcb6c/small/Screen-and-remote.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/d198bf15-af1c-4e7e-bac7-2da332cbcb6c/large/Screen-and-remote.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Checking bags?",
                        description:
                          'Save $10 by pre-paying for your baggage up to 24 hours before your departure. Pre-pay for your baggage under "Manage trips" on westjet.com',
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/887e5820-d0ee-4d41-ba23-7ec18ddd1943/small/carry-on-fee-01.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/887e5820-d0ee-4d41-ba23-7ec18ddd1943/large/carry-on-fee-01.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Make your airport experience seamless",
                        description:
                          "WestJet Rewards members with Platinum and Gold status can skip the airport queues - with priority check-in, boarding and fast-track security.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/a4ba9ccc-b674-41a3-b4e5-1847e2512415/small/Person-carrying-luggage.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/a4ba9ccc-b674-41a3-b4e5-1847e2512415/large/Person-carrying-luggage.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Reducing carbon emissions",
                        description:
                          "WestJet is partnering with Aero Design Labs to modify its 737-700 NG aircraft for improved fuel efficiency and reduced emissions.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/c64996cc-f087-4866-b0a1-5f3fd3ec2526/small/spa.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/c64996cc-f087-4866-b0a1-5f3fd3ec2526/large/spa.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "That extended comfort feeling",
                        description:
                          "Upgrade to Extended Comfort for ease across your entire journey with priority boarding, extra legroom, and early access to overhead bins.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/b5855c53-5c30-4d7c-a896-ae58853e2007/small/Economy_Seat-Icon.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/b5855c53-5c30-4d7c-a896-ae58853e2007/large/Economy_Seat-Icon.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                    ],
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "Unknown",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        outgoing: true,
                        brand: {
                          amadeusFareBrand: {
                            brandId: "BASIC",
                            brandName: "ULTRABASIC",
                          },
                        },
                        carrierCabinClass: "Y",
                        code: "LCQD0ZBJ",
                        codes: {},
                        validatingCarrierCode: "WS",
                        sliceIndex: 0,
                        bookingCode: "E",
                        privateFare: false,
                        isSelfTransferLayover: false,
                        availableSeats: 7,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: ["0 N"],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 75.4,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: ["First checked baggage"],
                            },
                            {
                              firstPiece: 1,
                              amount: 109.2,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: ["First checked baggage"],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Economy",
                      },
                    ],
                  },
                  sliceIndex: 0,
                },
                {
                  fareShelf: {
                    rating: 0,
                    brandName: "Ultrabasic",
                    shortBrandName: "Ultrabasic",
                    opaqueMapperStep: "bookingCode",
                  },
                  outgoing: false,
                  amenitiesUtas: {
                    amenities: {
                      seat: {
                        flatness: "not_flat",
                        info: {
                          displayText: '30" seat pitch',
                          quality: "na",
                        },
                        pitch: "30",
                        legroom: "standard",
                        width: "standard",
                      },
                      aircraft: {
                        info: {
                          displayText: "737 (narrowbody)",
                          quality: "na",
                        },
                      },
                      entertainment: {
                        info: {
                          displayText: "Streaming entertainment",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                      beverage: {
                        info: {
                          displayText: "Alcohol (fee) & beverages provided",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                      wifi: {
                        info: {
                          displayText: "Basic web browsing (fee)",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                      layout: {
                        info: {
                          displayText: "3-3 seat layout",
                          quality: "na",
                        },
                      },
                      power: {
                        info: {
                          displayText: "Power & USB outlets",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                      freshFood: {
                        info: {
                          displayText: "Light meal (fee)",
                          quality: "na",
                        },
                        exists: "yes",
                      },
                    },
                    utas: {
                      fare: {
                        brandName: "ULTRABASIC",
                        brandCodes: ["BASIC"],
                      },
                      utas: [
                        {
                          headline: "Standard",
                          description: "Standard boarding",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/1d4b32be-5e44-405b-8ad2-7036a516e8e3/small/boarding-priority.png?channel_id=gyr8kszn",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/1d4b32be-5e44-405b-8ad2-7036a516e8e3/large/boarding-priority.png?channel_id=gyr8kszn",
                          },
                          bagLimits: {},
                          category: "boarding-priority",
                          assessment: "restriction",
                        },
                        {
                          headline: "1 personal item",
                          description: "1 personal item",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/07d977fd-089e-47ce-88a6-c0b092a357df/small/carry-on-allowance.png",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/07d977fd-089e-47ce-88a6-c0b092a357df/large/carry-on-allowance.png",
                          },
                          bagLimits: {},
                          category: "carry-on-allowance",
                          assessment: "restriction",
                        },
                        {
                          headline: "Standard",
                          description: "Standard check-in priority",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/9534e76e-c360-4238-8429-a60c30b34fb7/small/check-in-priority.png?channel_id=gyr8kszn",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/9534e76e-c360-4238-8429-a60c30b34fb7/large/check-in-priority.png?channel_id=gyr8kszn",
                          },
                          bagLimits: {},
                          category: "check-in-priority",
                          assessment: "restriction",
                        },
                        {
                          headline: "1st for USD48  - 2nd for USD62",
                          description:
                            "First checked bag for USD48.17 and Second checked bag for USD62.99 per passenger",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/6b105b08-49e0-44d1-92bd-18a28bb9bb00/small/checked-bag-allowance.png",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/6b105b08-49e0-44d1-92bd-18a28bb9bb00/large/checked-bag-allowance.png",
                          },
                          bagLimits: {
                            weightKg: "23",
                            sizeLcm: "158",
                          },
                          category: "checked-bag-allowance",
                          assessment: "fee",
                        },
                        {
                          headline: "Not allowed",
                          description: "Lounge access not allowed",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/376f76bb-d432-4f63-ae51-54fd2034156c/small/lounge-access.png?channel_id=gyr8kszn",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/376f76bb-d432-4f63-ae51-54fd2034156c/large/lounge-access.png?channel_id=gyr8kszn",
                          },
                          bagLimits: {},
                          category: "lounge-access",
                          assessment: "restriction",
                        },
                        {
                          headline: "Assigned or for a fee",
                          description:
                            "Seat assigned or select anytime for a fee",
                          icon: {
                            smallIconUrl:
                              "https://upamedia.atpco.net/icons/5f555793-30c5-4fa3-b1af-1f984ab9dc1c/small/Seat-Selection.png?channel_id=gyr8kszn",
                            largeIconUrl:
                              "https://upamedia.atpco.net/icons/5f555793-30c5-4fa3-b1af-1f984ab9dc1c/large/Seat-Selection.png?channel_id=gyr8kszn",
                          },
                          bagLimits: {},
                          category: "seat-selection",
                          assessment: "fee",
                        },
                        {
                          headline: "Free",
                          description: "Change allowed for free",
                          bagLimits: {},
                          category: "advance-change",
                          assessment: "benefit",
                        },
                      ],
                      changePolicy: {
                        anytime: {
                          headline: "Free",
                          description: "Change allowed for free",
                          assessment: "Benefit",
                        },
                      },
                      cancellationPolicy: {
                        afterDeparture: {
                          headline: "Non-refundable",
                          description: "Non-refundable",
                          assessment: "Restriction",
                        },
                        beforeDeparture: {
                          headline: "Non-refundable",
                          description: "Non-refundable",
                          assessment: "Restriction",
                        },
                      },
                    },
                    upas: [
                      {
                        headline: "Recharge during your WestJet experience",
                        description:
                          'Charge all your devices before you reach your destination with AC power outlets and USB ports at each Economy 31" / 79cm seat.',
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/4f93d0b1-9b47-4850-95bb-2324fa034c5f/small/Seat.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/4f93d0b1-9b47-4850-95bb-2324fa034c5f/large/Seat.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Inflight food and beverages",
                        description:
                          "Choose from a selection of snacks and beverages. Our food service and menu offering vary by cabin, aircraft and flight duration.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/1c80bc11-ea80-4aa1-aa6a-a7e4d04879eb/small/Snack.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/1c80bc11-ea80-4aa1-aa6a-a7e4d04879eb/large/Snack.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Get your head in the clouds",
                        description:
                          "Relax and enjoy hundreds of hours of movies and TV episodes with WestJet Connect.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/d198bf15-af1c-4e7e-bac7-2da332cbcb6c/small/Screen-and-remote.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/d198bf15-af1c-4e7e-bac7-2da332cbcb6c/large/Screen-and-remote.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Checking bags?",
                        description:
                          'Save $10 by pre-paying for your baggage up to 24 hours before your departure. Pre-pay for your baggage under "Manage trips" on westjet.com',
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/887e5820-d0ee-4d41-ba23-7ec18ddd1943/small/carry-on-fee-01.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/887e5820-d0ee-4d41-ba23-7ec18ddd1943/large/carry-on-fee-01.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Make your airport experience seamless",
                        description:
                          "WestJet Rewards members with Platinum and Gold status can skip the airport queues - with priority check-in, boarding and fast-track security.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/a4ba9ccc-b674-41a3-b4e5-1847e2512415/small/Person-carrying-luggage.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/a4ba9ccc-b674-41a3-b4e5-1847e2512415/large/Person-carrying-luggage.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "Reducing carbon emissions",
                        description:
                          "WestJet is partnering with Aero Design Labs to modify its 737-700 NG aircraft for improved fuel efficiency and reduced emissions.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/c64996cc-f087-4866-b0a1-5f3fd3ec2526/small/spa.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/c64996cc-f087-4866-b0a1-5f3fd3ec2526/large/spa.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                      {
                        headline: "That extended comfort feeling",
                        description:
                          "Upgrade to Extended Comfort for ease across your entire journey with priority boarding, extra legroom, and early access to overhead bins.",
                        icon: {
                          smallIconUrl:
                            "https://upamedia.atpco.net/icons/b5855c53-5c30-4d7c-a896-ae58853e2007/small/Economy_Seat-Icon.png?channel_id=gyr8kszn",
                          largeIconUrl:
                            "https://upamedia.atpco.net/icons/b5855c53-5c30-4d7c-a896-ae58853e2007/large/Economy_Seat-Icon.png?channel_id=gyr8kszn",
                        },
                        cta: {
                          text: "",
                          url: "",
                        },
                        category: "unrecognized",
                      },
                    ],
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "Unknown",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        outgoing: false,
                        brand: {
                          amadeusFareBrand: {
                            brandId: "BASIC",
                            brandName: "ULTRABASIC",
                          },
                        },
                        carrierCabinClass: "Y",
                        code: "LCQD0ZBJ",
                        codes: {},
                        validatingCarrierCode: "WS",

                        bookingCode: "E",
                        privateFare: false,
                        isSelfTransferLayover: false,
                        availableSeats: 9,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: ["0 N"],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 75.4,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: ["First checked baggage"],
                            },
                            {
                              firstPiece: 1,
                              amount: 109.2,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: ["First checked baggage"],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Economy",
                      },
                    ],
                  },
                },
              ],
              multiCity: false,
              multiTicket: false,
            },
          ],
          id: "4a088a30-358a-4734-acc8-5401608412b3/yNMH443qMdEG67yGHmIugEIF92MEsh",
          validatingCarrierCode: "WS",
          tripDescription:
            "Flight WS711/WS714 YYZ<->YVR 2024-10-25 to 2024-10-28",
          slices: [
            {
              departureTime: "2024-10-25T12:00:00.000-04:00",
              zonedDepartureTime: "2024-10-25T12:00-04:00",
              outgoing: true,
              arrivalTime: "2024-10-25T14:14:00.000-07:00",
              destinationName: "Vancouver International Airport",
              originCode: "YYZ",
              id: "1724d7a6-bf83-3502-8277-0cd262f1acde",
              segmentDetails: [
                {
                  departureTime: "2024-10-25T12:00:00.000-04:00",
                  airlineName: "WestJet",
                  airlineCode: "WS",
                  zonedDepartureTime: "2024-10-25T12:00-04:00",
                  zonedArrivalTime: "2024-10-25T14:14-07:00",
                  plusDays: 0,
                  marketingAirline: {
                    code: "WS",
                    name: "WestJet",
                  },
                  arrivalTime: "2024-10-25T14:14:00.000-07:00",
                  destinationName: "Vancouver International Airport",
                  originCode: "YYZ",
                  originName: "Lester B. Pearson Intl Airport",
                  operatingAirline: {
                    code: "WS",
                    name: "WestJet",
                  },
                  flightNumber: "711",
                  destinationCode: "YVR",
                  stops: 0,
                },
              ],
              originName: "Lester B. Pearson Intl Airport",
              totalDurationMinutes: 314,
              destinationCode: "YVR",
              stops: 0,
            },
            {
              departureTime: "2024-10-28T13:15:00.000-07:00",
              zonedDepartureTime: "2024-10-28T13:15-07:00",
              outgoing: false,
              arrivalTime: "2024-10-28T20:43:00.000-04:00",
              destinationName: "Lester B. Pearson Intl Airport",
              originCode: "YVR",
              id: "6107018c-36ae-36e8-82cb-d58172a19e29",
              segmentDetails: [
                {
                  departureTime: "2024-10-28T13:15:00.000-07:00",
                  airlineName: "WestJet",
                  airlineCode: "WS",
                  zonedDepartureTime: "2024-10-28T13:15-07:00",
                  zonedArrivalTime: "2024-10-28T20:43-04:00",
                  plusDays: 0,
                  marketingAirline: {
                    code: "WS",
                    name: "WestJet",
                  },
                  arrivalTime: "2024-10-28T20:43:00.000-04:00",
                  destinationName: "Lester B. Pearson Intl Airport",
                  originCode: "YVR",
                  originName: "Vancouver International Airport",
                  operatingAirline: {
                    code: "WS",
                    name: "WestJet",
                  },
                  flightNumber: "714",
                  destinationCode: "YYZ",
                  stops: 0,
                },
              ],
              originName: "Vancouver International Airport",
              totalDurationMinutes: 268,
              destinationCode: "YYZ",
              stops: 0,
            },
          ],
        } as unknown as TripDetails,
        selectedTrip: {
          tripId:
            "4a088a30-358a-4734-acc8-5401608412b3/yNMH443qMdEG67yGHmIugEIF92MEsh",
          outgoingSliceId: "cad24f21-310a-3037-82ff-94951d66e821",
          outgoingFareId: "cb797dc7-25b5-4031-8875-bde403ca12eb",
          outgoingFareRating: 0,
          returnSliceId: "6107018c-36ae-36e8-82cb-d58172a19e29",
          returnFareId: "0e119044-a8f8-49d4-ab0f-37ac01973baa",
          returnFareRating: 0,
        } as ISelectedTrip,
        shopPricingInfo: {
          fare: undefined,
        },
        departureLabel: "",
        returnLabel: undefined,
        airports: undefined,
      },
      common: {
        tripPricing: undefined,
      },
      [ParentState.cancelForAnyReason]: {
        cfarQuoteId: undefined,
        cfarOffers: undefined,
      },
      [ParentState.changeForAnyReason]: { ...getAirChfarInitialContext() },
      [ParentState.passengerInformation]: {
        selectedPassengerIds: [] as Array<PersonId>,
        selectedLapInfantIds: [] as Array<PersonId>,
        userPassengers: [] as Array<IPerson>,
        addPassport: true,
        infantOnlyError: false,
        visited: false,
      },
      [ParentState.contactInformation]: {
        contactInfo: {} as IContactInfo,
        visited: false,
      },
      [ParentState.passport]: {
        error: undefined,
      },
      [ParentState.seatSelection]: {
        selectedSeats: [],
        selectedSeatSegments: [],
        cheapestSeat: undefined,
        seatMapHtml: undefined,
        seatMapRequest: undefined,
        seatsInfo: undefined,
        seatMapAvailable: undefined,
        visited: false,
        skipSeatSelection: false,
      },
      [ParentState.review]: {
        visited: false,
      },
      // [ParentState.awardsPayment]: {
      //   selectedAccount: null,
      //   awardsAccounts: [],
      //   awardsAmountToApply: 0,
      //   awardsCashAmountToApply: 0,
      //   visited: false,
      //   error: false,
      // },
      [ParentState.cardPayment]: {
        paymentMethods: [],
        selectedPaymentMethodId: undefined,
        spreedlyToken: undefined,
        error: undefined,
        visited: false,
      },
      [ParentState.bookingConfirmation]: {},
      [ParentState.disruption]: {
        selectedOffers: [],
        offersResponse: [],
      },
      [ParentState.priceDrop]: {
        candidateId: undefined,
      },
      [ParentState.changeForAnyReasonDiscount]: {},
      [ParentState.wallet]: { ...getIntialWalletContext() },
      // walletPayment: {
      //   creditPaymentAmount: {
      //     value: 0,
      //     currencyCode: undefined,
      //     currencySymbol: undefined,
      //   },
      //   creditBalance: {
      //     value: 0,
      //     currencyCode: undefined,
      //     currencySymbol: undefined,
      //   },
      // },
    };
  };
