import {
  CardPaymentEventTypes,
  CartQuoteGuardTypes,
  ContactEventType,
  FlightPassengerEventTypes,
  FlightServiceTypes,
  GenericChildState,
  ParentState,
  PaymentInformationChildState,
  PaymentInformationState,
  getCardPaymentMachine,
  getCartFulfillMachine,
  getCartQuoteMachine,
  getCartUpdateMachine,
  getContactInformationMachine,
  getFlightPassengerInformationMachine,
  getPassportMachineState,
  getPriceFreezeDiscountMachineState,
  getPriceFreezeMachineState,
  getReviewMachineState,
  getSeatMachine,
  getWalletMachine,
} from "@capone/checkout";
import { MachineConfig } from "xstate";
import { ActionTypes } from "./actions";
import { setPaymentFulfillParams } from "./actions/common";
// import { getAirDisruptionMachine } from "./childStateMachine/AirDisruption";
import { Event, TEvent } from "./events";
import { getInitialPackagesMachineContext } from "./initialContext";
import { ServiceTypes } from "./services";
import { PackagesMachineContext } from "./types";

export const packagesMachineId = "capone-packages-checkout";

const originalCardPaymentMachine = getCardPaymentMachine({
  parentMachineId: packagesMachineId,
  nextState: `#${packagesMachineId}.${ParentState.review}`,
  previousState: `#${packagesMachineId}.${ParentState.seatSelection}`,
});
const cardPaymentMachine = {
  ...originalCardPaymentMachine,
  states: {
    ...originalCardPaymentMachine.states,
    [PaymentInformationState.add]: {
      initial: PaymentInformationChildState.idle,
      states: {
        [PaymentInformationChildState.idle]: {},
        [PaymentInformationChildState.verify]: {
          invoke: {
            src: ServiceTypes.verifyPayment,
            onDone: {
              actions: [ActionTypes.setPaymentMethodId],
              target: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
            },
            onError: {
              actions: ActionTypes.setPaymentError,
              target: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.error}`,
            },
          },
        },
      },
    },
  },
};

export const packagesBookStateMachine: MachineConfig<
  PackagesMachineContext,
  any,
  TEvent
> = {
  schema: {
    context: getInitialPackagesMachineContext(),
    events: {} as TEvent,
  },

  // Machine identifier
  id: packagesMachineId,

  // Initial state
  initial: ParentState.passengerInformation, // temporarily start at flight passenger selection (TODO: change back to loading)

  // State definitions

  states: {
    [ParentState.priceFreeze]: {
      ...getPriceFreezeMachineState({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.loading}`,
      }),
    },
    [ParentState.loading]: {
      initial: "fetchTripPricing",
      states: {
        fetchTripPricing: {
          invoke: {
            src: FlightServiceTypes.fetchTripPricingService,
            onDone: {
              actions: ActionTypes.setTripPricingFromData,
              target: `listPaymentMethods`,
            },
            onError: {
              actions: ActionTypes.setTripPricingError,
              target: `listPaymentMethods`,
            },
          },
        },
        listPaymentMethods: {
          invoke: {
            src: ServiceTypes.listPaymentMethods,
            onDone: {
              target: `#${packagesMachineId}.${ParentState.passengerInformation}`,
              actions: ActionTypes.handleListPayments,
            },
            onError: {
              target: `#${packagesMachineId}.${ParentState.passengerInformation}`,
              actions: ActionTypes.setPaymentError,
            },
          },
        },
      },
    },
    [ParentState.passengerInformation]: {
      ...getFlightPassengerInformationMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.contactInformation}`,
        nextStateFromReview: `#${packagesMachineId}.${ParentState.contactInformation}`,
      }),
    },
    [ParentState.contactInformation]: {
      ...getContactInformationMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cartQuote}`,
        nextStateFromReview: `#${packagesMachineId}.${ParentState.cartQuote}`,
      }),
    },
    [ParentState.passport]: {
      ...getPassportMachineState({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.passengerInformation}`,
        previousState: `#${packagesMachineId}.${ParentState.passengerInformation}`,
      }),
    },
    [ParentState.cartQuote]: {
      entry: ActionTypes.addMultiProviderProduct,
      ...getCartQuoteMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.wallet}`,
      }),
    },
    // Add Products to Cart
    // [ParentState.cancelForAnyReason]: {
    //   ...getCancelForAnyReasonMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.disruption}`,
    //   }),
    // },
    // [ParentState.changeForAnyReason]: {
    //   ...getChfarMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.disruption}`,
    //   }),
    // },
    // [ParentState.disruption]: {
    //   ...getAirDisruptionMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.priceDrop}`,
    //   }),
    // },
    // [ParentState.priceDrop]: {
    //   ...getAirPriceDropMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.vipSupport}`,
    //   }),
    // },
    // [ParentState.vipSupport]: {
    //   ...getVipSupportMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.priceFreezeDiscount}`,
    //   }),
    // },
    //Add Discounts to Cart
    [ParentState.priceFreezeDiscount]: {
      ...getPriceFreezeDiscountMachineState({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.wallet}`,
      }),
    },
    [ParentState.wallet]: {
      ...getWalletMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cartUpdate}`,
      }),
    },

    // Update cart with Products and Discounts
    [ParentState.cartUpdate]: {
      ...getCartUpdateMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.seatSelection}`,
      }),
      exit: ActionTypes.checkForPriceChange,
    },
    [ParentState.seatSelection]: {
      ...getSeatMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cardPayment}`,
        previousState: `#${packagesMachineId}.${ParentState.contactInformation}`,
      }),
    },
    [ParentState.cardPayment]: cardPaymentMachine,
    [ParentState.review]: {
      initial: GenericChildState.idle,
      states: {
        [GenericChildState.loading]: {
          //   invoke: {
          //     src: ServiceTypes.trackReviewDetails,
          //     onDone: `#${packagesMachineId}.${ParentState.review}.${GenericChildState.idle}`,
          //     onError: `#${packagesMachineId}.${ParentState.review}.${GenericChildState.idle}`,
          //   },
        },
        [GenericChildState.idle]: {},
      },
      ...getReviewMachineState({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        previousState: `#${packagesMachineId}.${ParentState.cardPayment}`,
      }),
      //   exit: ActionTypes.removeVipProductFromCart,
    },
    [ParentState.cartUpdateBeforeFulfill]: {
      ...getCartUpdateMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cartFulfill}`,
      }),
    },
    [ParentState.cartFulfill]: {
      entry: setPaymentFulfillParams,
      ...getCartFulfillMachine<PackagesMachineContext>({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.bookingConfirmation}`,
        // trackPollSuccessAction: trackPollSuccess({
        //   eventName: COMPLETE_BUY_AIR,
        //   // trackProperties returned in pollfulfill response will be also added
        //   getAdditionalProperties: (context, event) => {
        //     return {
        //       credit_amount_used_aud: getWalletCreditPayAmount({ context })
        //         ?.value,
        //       customer_credit_balance_aud: getWalletCreditBalance({ context })
        //         ?.value,
        //       cfar_choice: AirCfarSelectors.getIsCfarAttached({ context }),
        //       total_card_amount_aud: getAmountDue({ context }),
        //       total_rewards_amount_aud:
        //         AwardsPaymentSelectors.getAwardsCashAmountToApply({ context }),
        //       card_product_used: getSelectedPaymentCardName({ context }),
        //       rewards_product_used: AwardsPaymentSelectors.getSelectedAccount({
        //         context,
        //       })?.cardProductType,
        //       awards_balance: AwardsPaymentSelectors.getSelectedAccount({
        //         context,
        //       })?.awardsBalance?.value,
        //       agent_locator:
        //         cartFulfillSelectors.getFulfilledProductsReservationId({
        //           context,
        //         }),
        //     };
        //   },
        // }),
        // trackPollFailureAction: trackPollFailure({
        //   eventName: COMPLETE_BUY_AIR,
        //   // trackProperties returned in pollfulfill response will be also added
        //   getAdditionalProperties: (context, event) => {
        //     return {
        //       credit_amount_used_aud: getWalletCreditPayAmount({ context })
        //         ?.value,
        //       customer_credit_balance_aud: getWalletCreditBalance({ context })
        //         ?.value,
        //       cfar_choice: AirCfarSelectors.getIsCfarAttached({ context }),
        //       total_card_amount_aud: getAmountDue({ context }),
        //       total_rewards_amount_aud:
        //         AwardsPaymentSelectors.getAwardsCashAmountToApply({ context }),
        //       card_product_used: getSelectedPaymentCardName({ context }),
        //       rewards_product_used: AwardsPaymentSelectors.getSelectedAccount({
        //         context,
        //       })?.cardProductType,
        //       awards_balance: AwardsPaymentSelectors.getSelectedAccount({
        //         context,
        //       })?.awardsBalance?.value,
        //     };
        //   },
        // }),
      }),
    },
    [ParentState.bookingConfirmation]: {},
  },
  on: {
    [Event.OPEN_PASSENGER_PICKER]: `#${packagesMachineId}.${ParentState.passengerInformation}`,
    // [VipSupportEventTypes.TOGGLE_SUPPORT_STATUS]: {
    //   actions: ActionTypes.toggleVipSupport,
    // },
    // // wallet credit Payment Events,
    // [WalletPaymentEventTypes.SET_WALLET_CREDIT_PAY_AMOUNT]: {
    //   actions: ActionTypes.setWalletCreditPayAmount,
    // },
    // [WalletPaymentEventTypes.SET_WALLET_CREDIT_BALANCE]: {
    //   actions: ActionTypes.setWalletCreditBalance,
    // },
    // // Awards Payment Events,
    // [AwardsPaymentEventTypes.SET_SELECTED_AWARDS_ACCOUNT]: {
    //   actions: ActionTypes.setSelectedAwardsAccountId,
    // },
    // [AwardsPaymentEventTypes.SET_AWARDS_CASH_AMOUNT_TO_PAY]: {
    //   actions: ActionTypes.setAwardsCashAmountToPay,
    // },
    // [AwardsPaymentEventTypes.SET_AWARDS_AMOUNT_TO_PAY]: {
    //   actions: ActionTypes.setAwardsAmountToPay,
    // },
    // [AwardsPaymentEventTypes.SET_AWARDS_PAYMENT_VISITED]: {
    //   actions: ActionTypes.setAwardsPaymentVisited,
    // },
    // [AwardsPaymentEventTypes.SET_AWARDS_ACCOUNTS]: {
    //   actions: ActionTypes.setAwardsAccounts,
    // },
    // Card Payment Events
    [CardPaymentEventTypes.SET_SELECTED_PAYMENT_METHOD_ID]: {
      actions: ActionTypes.setSelectedPaymentMethodId,
    },
    [CardPaymentEventTypes.DELETE_PAYMENT_METHOD]: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.delete}`,
    [CardPaymentEventTypes.OPEN_PAYMENT_FORM]: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}`,
    [CardPaymentEventTypes.CLOSE_PAYMENT_FORM]: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
    [CardPaymentEventTypes.VERIFY_PAYMENT_METHOD]: {
      actions: ActionTypes.setSpreedlyToken,
      target: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}.${PaymentInformationChildState.verify}`,
    },
    // Contact Information Events
    [ContactEventType.CHANGE]: {
      actions: ActionTypes.setContactInfo,
    },
    // Events below are used for browser navigation
    [Event.GO_TO_PASSENGER_SELECT]: `#${packagesMachineId}.${ParentState.passengerInformation}`,
    [Event.GO_TO_CONTACT_INFORMATION]: `#${packagesMachineId}.${ParentState.contactInformation}`,
    [Event.GO_TO_CART_QUOTE]: `#${packagesMachineId}.${ParentState.cartQuote}`,
    [Event.GO_TO_REVIEW]: `#${packagesMachineId}.${ParentState.review}`,
    [Event.GO_TO_CARD_PAYMENT]: `#${packagesMachineId}.${ParentState.cardPayment}`,
    [Event.GO_TO_BOOKING_CONFIRMATION]: `#${packagesMachineId}.${ParentState.bookingConfirmation}`,
    [Event.GO_TO_SEAT_SELECTION]: [
      {
        target: `#${packagesMachineId}.${ParentState.cartQuote}`,
        cond: CartQuoteGuardTypes.isCartLocked,
      },
      {
        target: `#${packagesMachineId}.${ParentState.seatSelection}`,
      },
    ],
    // [Event.CBA_SKIP_SEAT]: {
    //   actions: [
    //     ActionTypes.setSkipSeatSelection,
    //     ActionTypes.resetSelectedSeats,
    //   ],
    // },
    // [Event.CBA_SELECT_SEAT]: {
    //   actions: [ActionTypes.selectSeat, ActionTypes.resetSkippedSeatSelection],
    // },
    [FlightPassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER]: {
      target: `#${packagesMachineId}.${ParentState.passengerInformation}`,
      actions: ActionTypes.openFormAndSetCurrentPassenger,
    },
  },
};
