import { Box } from "@material-ui/core";
import React from "react";
import { FlightList } from "../FlightList";
import "./styles.scss";
import { RewardsAccountSelection } from "../../../rewards/components";
import { PackagesShopProgressBar } from "../../../book/components";

export const MobileFlightShop = () => {
  return (
    <Box className={"mobile-package-flight-shop-root"}>
      <PackagesShopProgressBar isMobile />
      <Box className="mobile-hotel-shop-rewards-account-contents">
        <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
      </Box>
      <FlightList />
    </Box>
  );
};
