import { Box, Typography } from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PackagesDetailsPanelConnectorProps } from "./container";
import "./styles.scss";
import { Icon, IconName } from "halifax";

export interface IPackagesDetailsPanelProps
  extends RouteComponentProps,
    PackagesDetailsPanelConnectorProps {}

export const PackageDetailsPanel = ({}: IPackagesDetailsPanelProps) => {
  return (
    <Box className="package-details-panel-root">
      <Box className="package-details-panel-section">
        <Box className="package-details-panel-section-icon-wrapper">
          <Box className="icon-circle">
            <Icon name={IconName.HotelFunnelIcon} />
          </Box>
        </Box>
        <Box className="package-details-panel-section-details-wrapper">
          <Typography className="package-details-panel-section-name">
            San Juan Hotel, Miami
          </Typography>
          <Box className="package-details-panel-section-details-row">
            <Typography>Standard two double beds</Typography> |{" "}
            <Typography>Room fits (2) guests</Typography> |{" "}
            <Typography>
              <strong>Check-in:</strong> Mon, July 1
            </Typography>{" "}
            |{" "}
            <Typography>
              <strong>Checkout:</strong> Mon, July 8
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="package-details-section-separator">
        <Box className="icon-circle">
          <span>+</span>
        </Box>
      </Box>
      <Box className="package-details-panel-section">
        <Box className="package-details-panel-section-icon-wrapper">
          <Box className="icon-circle">
            <Icon name={IconName.FlightFunnelIcon} />
          </Box>
        </Box>
        <Box className="package-details-panel-section-details-wrapper">
          <Typography className="package-details-panel-section-name">
            <strong>New York</strong> (JFK) to <strong>Miami</strong> (MIA)
          </Typography>
          <Box className="package-details-panel-section-details-row">
            <Typography>2 travelers</Typography> |{" "}
            <Typography>Round-trip flight</Typography> |{" "}
            <Typography>
              <strong>Outbound:</strong> Mon, July 1
            </Typography>{" "}
            |{" "}
            <Typography>
              <strong>Return:</strong> Mon, July 8
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
