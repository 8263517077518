import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";

import { PackageHotelShop } from "./component";
import { actions } from "./actions";
import { getDepartureDate, getReturnDate } from "../search/reducer";
import { getHotelShopCallState, getPackageSelectedLodging } from "./reducer";
import { listPaymentMethods } from "../availability/actions/actions";
import { fetchTravelWalletDetails } from "../travel-wallet/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getDepartureDate(state),
    untilDate: getReturnDate(state),
    hotelShopCallState: getHotelShopCallState(state),
    selectedLodging: getPackageSelectedLodging(state),
  };
};

export const mapDispatchToProps = {
  fetchPackageHotelShop: actions.fetchPackageHotelShop,
  fetchTravelWalletDetails,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PackageHotelShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPackageHotelShop = withRouter(
  connector(PackageHotelShop)
);
