export const FETCH_PACKAGE_FLIGHT_SHOP =
  "packagesFlightShop/FETCH_PACKAGE_FLIGHT_SHOP";
export type FETCH_PACKAGE_FLIGHT_SHOP = typeof FETCH_PACKAGE_FLIGHT_SHOP;

export const SET_PACKAGE_FLIGHT_SHOP_RESULTS =
  "packagesFlightShop/SET_PACKAGE_FLIGHT_SHOP_RESULTS";
export type SET_PACKAGE_FLIGHT_SHOP_RESULTS =
  typeof SET_PACKAGE_FLIGHT_SHOP_RESULTS;

export const SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED =
  "packagesFlightShop/SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED";
export type SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED =
  typeof SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED;

export const FETCH_PACKAGES_TRIP_DETAILS =
  "packagesFlightShop/FETCH_PACKAGES_TRIP_DETAILS";
export type FETCH_PACKAGES_TRIP_DETAILS = typeof FETCH_PACKAGES_TRIP_DETAILS;

export const SET_PACKAGES_TRIP_DETAILS =
  "packagesFlightShop/SET_PACKAGES_TRIP_DETAILS";
export type SET_PACKAGES_TRIP_DETAILS = typeof SET_PACKAGES_TRIP_DETAILS;

export const SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED =
  "packagesFlightShop/SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED";
export type SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED =
  typeof SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED;

export const SET_PACKAGES_CHOSEN_OUTGOING_SLICE =
  "packagesFlightShop/SET_PACKAGES_CHOSEN_OUTGOING_SLICE";
export type SET_PACKAGES_CHOSEN_OUTGOING_SLICE =
  typeof SET_PACKAGES_CHOSEN_OUTGOING_SLICE;

export const SET_PACKAGES_CHOSEN_RETURN_SLICE =
  "packagesFlightShop/SET_PACKAGES_CHOSEN_RETURN_SLICE";
export type SET_PACKAGES_CHOSEN_RETURN_SLICE =
  typeof SET_PACKAGES_CHOSEN_RETURN_SLICE;

export const SET_PACKAGES_CHOSEN_DEPARTURE_SLICE =
  "packagesFlightShop/SET_PACKAGES_CHOSEN_DEPARTURE_SLICE";
export type SET_PACKAGES_CHOSEN_DEPARTURE_SLICE =
  typeof SET_PACKAGES_CHOSEN_DEPARTURE_SLICE;

export const SET_PACKAGES_FLIGHT_SHOP_PROGRESS =
  "packagesFlightShop/SET_PACKAGES_FLIGHT_SHOP_PROGRESS";
export type SET_PACKAGES_FLIGHT_SHOP_PROGRESS =
  typeof SET_PACKAGES_FLIGHT_SHOP_PROGRESS;
