import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { CallState } from "../../../rewards/reducer";
import { FlightShopStep } from "redmond";

export const getFlightShopCallState = (state: IStoreState) =>
  state.packagesFlightShop.packagesFlightShopCallState;

export const getFlightShopCallResults = (state: IStoreState) =>
  state.packagesFlightShop.packagesFlightShopResults;

export const getAirShopSummary = createSelector(
  getFlightShopCallResults,
  (flightShopCallResults) => {
    return flightShopCallResults?.airShopSummary.value;
  }
);

export const getFlightsToRender = createSelector(
  getAirShopSummary,
  (airShopSummary) => {
    return airShopSummary ? airShopSummary.flights : {};
  }
);

export const getOutboundFlightsToRender = createSelector(
  getAirShopSummary,
  (airShopSummary) => {
    return airShopSummary ? airShopSummary.flights.outbound : [];
  }
);

export const getReturnFlightsToRender = createSelector(
  getAirShopSummary,
  (airShopSummary) => {
    return airShopSummary ? airShopSummary.flights.outbound : [];
  }
);

export const getPackagesByOutboundFareSlice = createSelector(
  getFlightShopCallResults,
  (flightShopCallResults) => {
    return flightShopCallResults?.packagesByOutboundFareSlice;
  }
);

export const getIsFlightShopLoading = createSelector(
  getFlightShopCallState,
  (flightShopCallState) => {
    return flightShopCallState === CallState.InProcess;
  }
);

export const getIsTripDetailsLoading = (state: IStoreState) =>
  state.packagesFlightShop.tripDetailsLoading;

export const getFareTripDetailsById = (state: IStoreState) =>
  state.packagesFlightShop.tripDetailsById;

export const getSelectedTrip = (state: IStoreState) =>
  state.packagesFlightShop.selectedTrip;

export const getFlightShopProgress = (state: IStoreState) =>
  state.packagesFlightShop.packagesFlightShopProgress;

export const getIsOutgoing = createSelector(
  getFlightShopProgress,
  (flightShopProgress) => {
    return flightShopProgress === FlightShopStep.ChooseDeparture;
  }
);
