import {
  AirChfarActions,
  AirDisruptionActions,
  AirPFExerciseActions,
  AirPriceDropActions,
  CardPaymentActions,
  CartActions,
  CfarActions,
  ContactInformationActions,
  FlightActions,
  FlightPassengerActions,
  PassportActions,
  ReviewActions,
  SeatActions,
  WalletActions,
  getObjectKeysAsObject,
} from "@capone/checkout";
// import { actions as CBASeatActions } from "../childStateMachine/seats/actions";
import { setPaymentFulfillParams } from "./common";

export const Actions: any = {
  ...FlightActions,
  ...CartActions,
  ...AirDisruptionActions,
  ...CfarActions,
  ...ContactInformationActions,
  ...FlightPassengerActions,
  ...PassportActions,
  ...AirPFExerciseActions,
  ...ReviewActions,
  ...SeatActions,
  ...AirChfarActions,
  ...CardPaymentActions,
  ...WalletActions,
  // ...AwardsPaymentActions,
  ...AirPriceDropActions,
  // ...WalletPaymentActions,

  setPaymentFulfillParams,
  // put this after shared seat actions to override it
  // ...CBASeatActions,
};

export const ActionTypes = getObjectKeysAsObject(Actions);
