import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityList } from "./component";
import H from "history";
import { IStoreState } from "../../../../reducers/types";
import {
  getDepartureDate,
  getOrigin,
  getReturnDate,
} from "../../../search/reducer";
import {
  getPackageAvailabilitySearchDestinationLocationResult,
  getPackagesAvailabilityCallState,
  getPackagesByLodgingId,
  getSearchedNightCount,
  getTravelersCount,
  getFilteredAndSortedHotelAvailabilityLodgings,
  getIsFilteredHotelAvailabilityLodgingsEmpty,
  getRecommendedFlights,
  getPackageHotelQueryParams,
} from "../../reducer";
import {
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getRewardsAccountWithLargestEarnForBanner,
} from "../../../rewards/reducer";
import {
  IResult,
  ITripTerminus,
  Lodging,
  PackagedLodging,
  PackageFetchDetailsOpaqueValue,
  RewardsAccount,
  TravelWalletCredit,
} from "redmond";
import { PackagesAvailabilityCallState } from "../../reducer/state";
import { fetchMorePackagesAvailability } from "../../actions/actions";
import { Dispatch } from "@reduxjs/toolkit";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";

export enum FilterKey {
  Amenities = "Amenities",
  StarRatings = "StarRatings",
  PriceRange = "PriceRange",
  HotelName = "HotelName",
}

interface IOwnProps {}

interface IStateProps {
  returnDate: Date | null;
  departureDate: Date | null;
  nightCount: number | null;
  accountReferenceId: string | null;
  largestValueAccount: RewardsAccount;
  lodgings: Lodging[];
  packagesAvailabilityCallState: PackagesAvailabilityCallState;
  packagesByLodgingId?:
    | {
        [key: string]: PackagedLodging;
      }
    | undefined;
  searchedLocation: IResult | null;
  travelersCount: number;
  isFilteredHotelAvailabilityLodgingsEmpty: boolean;
  recommendedFlights: PackageFetchDetailsOpaqueValue;
  packageHotelQueryParams: any;
  origin: ITripTerminus | null;
  credit?: TravelWalletCredit;
}

const mapStateToProps = (state: IStoreState): IStateProps => {
  return {
    returnDate: getReturnDate(state),
    departureDate: getDepartureDate(state),
    nightCount: getSearchedNightCount(state),
    accountReferenceId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    lodgings: getFilteredAndSortedHotelAvailabilityLodgings(state),
    packagesAvailabilityCallState: getPackagesAvailabilityCallState(state),
    packagesByLodgingId: getPackagesByLodgingId(state),
    searchedLocation:
      getPackageAvailabilitySearchDestinationLocationResult(state),
    travelersCount: getTravelersCount(state),
    isFilteredHotelAvailabilityLodgingsEmpty:
      getIsFilteredHotelAvailabilityLodgingsEmpty(state),
    recommendedFlights: getRecommendedFlights(state),
    packageHotelQueryParams: getPackageHotelQueryParams(state),
    origin: getOrigin(state),
    credit: getTravelWalletCredit(state),
  };
};

interface IDispatchProps {
  fetchMorePackagesAvailability: (history: H.History) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    fetchMorePackagesAvailability: (history: H.History) =>
      dispatch(fetchMorePackagesAvailability(history)),
  };
};

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type AvailabilityListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityList = connector(
  withRouter(AvailabilityList)
);
