import {
  CallState,
  FlightShopStep,
  PackageFareDetails,
  ShopFlightsResponse,
  TripDetails,
} from "redmond";
import { actions, actionTypes } from "../actions";

export interface ITripDetailsByTripId {
  [key: string]: TripDetails;
}

export interface IPackagesFlightShopState {
  packagesFlightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  packagesFlightShopResults: ShopFlightsResponse | null;
  tripDetailsLoading: boolean | null;
  tripDetailsById: ITripDetailsByTripId;
  selectedTrip: ISelectedTrip | null;
  packagesFlightShopProgress: FlightShopStep;
}
export interface ISelectedTrip {
  tripId: string | null;
  outgoingSliceId: string | null;
  outgoingFareId: string | null;
  outgoingFareRating?: number;
  returnSliceId?: string | null;
  returnFareId?: string | null;
  returnFareRating?: number;
}

const initialState: IPackagesFlightShopState = {
  packagesFlightShopCallState: CallState.NotCalled,
  packagesFlightShopResults: null,
  selectedTrip: null,
  packagesFlightShopProgress: FlightShopStep.ChooseDeparture,
  tripDetailsLoading: null,
  tripDetailsById: {},
};

export const reducer = (
  state: IPackagesFlightShopState = initialState,
  action: actions.PackagesFlightShopActions
): IPackagesFlightShopState => {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_FLIGHT_SHOP:
      return {
        ...state,
        packagesFlightShopCallState: CallState.InProcess,
      };
    case actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS: {
      return {
        ...state,
        packagesFlightShopCallState: action.flightShopCallState,
        packagesFlightShopResults: action.flightShopResults,
      };
    }
    case actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED:
      return {
        ...state,
        packagesFlightShopCallState: CallState.Failed,
      };

    case actionTypes.FETCH_PACKAGES_TRIP_DETAILS:
      return {
        ...state,
        tripDetailsLoading: true,
      };
    case actionTypes.SET_PACKAGES_TRIP_DETAILS: {
      const { packagesByFareId, tripDetailsResponse } =
        action.tripDetailsResponse;
      let tripDetails = tripDetailsResponse.value as TripDetails;
      let amendedFareDetails: PackageFareDetails[] = [];
      tripDetails.fareDetails.map((fare) => {
        const packageForFare = packagesByFareId[fare.id];
        amendedFareDetails.push({
          ...fare,
          packagePricing: packageForFare.pricing,
          incrementalPricePerTraveler:
            packageForFare.incrementalPricePerTraveler,
        });
      });
      tripDetails.fareDetails = amendedFareDetails;
      const tripDetailsById = {
        ...state.tripDetailsById,
        [tripDetails.id]: tripDetails,
      };
      return {
        ...state,
        tripDetailsLoading: false,
        tripDetailsById,
      };
    }
    case actionTypes.SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        tripDetailsLoading: null,
      };
    case actionTypes.SET_PACKAGES_CHOSEN_OUTGOING_SLICE: {
      const {
        outgoingSliceId,
        outgoingFareRating,
        outgoingFareId,
        tripId,
        resetReturnIds,
      } = action;
      const selectedTrip = {
        ...state.selectedTrip,
        tripId,
        outgoingSliceId,
        outgoingFareId: outgoingFareId || null,
        outgoingFareRating: outgoingFareRating,
        // resetting the return ids are sometimes required depending on the current progress of FlightShop workflow
        ...(resetReturnIds && {
          returnSliceId: null,
          returnFareId: null,
        }),
      };
      return {
        ...state,
        selectedTrip,
      };
    }
    case actionTypes.SET_PACKAGES_CHOSEN_RETURN_SLICE: {
      const { returnSliceId, returnFareId, returnFareRating, tripId } = action;
      if (state.selectedTrip) {
        const selectedTrip = {
          ...state.selectedTrip,
          tripId,
          returnSliceId,
          returnFareRating,
          returnFareId: returnFareId || null,
        };
        return {
          ...state,
          selectedTrip,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case actionTypes.SET_PACKAGES_FLIGHT_SHOP_PROGRESS: {
      return {
        ...state,
        packagesFlightShopProgress: action.progress,
      };
    }
    default:
      return { ...state };
  }
};

export * from "./selectors";
