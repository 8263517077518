import { Box, Typography } from "@material-ui/core";
import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  Slice,
  IFlightListData,
  FareclassOptionFilter,
  AlgomerchTag,
  mapAlgomerchTexts,
  FiatPrice,
  FlightShopStep,
  TripDetails,
} from "redmond";
import { FlightListInfo } from "./components/FlightListInfo";
import { FlightCardType } from "./components/FlightListInfo/component";
import {
  B2BSpinner,
  BackButton,
  FareDetailsCardCtaTitles,
  FareDetailsCardCtaType,
  Header,
  LoadingIndicator,
  MobilePopoverCard,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import { IFlightListProps } from "./container";
import { ClientContext } from "../../../../App";
import {
  INITIAL_RESULT_SET_SIZE,
  LOADING_FLIGHT_DETAILS_STRING,
  RECOMMENDED_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT,
  RECOMMENDED_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT,
  SHOW_MORE_NUM,
} from "./components/FlightListInfo/textConstants";
import ReactList from "react-list";
import InfiniteScroll from "react-infinite-scroll-component";
import { FlightDetails } from "./components/FlightDetails";
import { FlightAlgomerchModal } from "../FlightAlgomerchModal";
import { ITripDetailsByTripId } from "../../reducer";

const DESKTOP_OFFSET_SCROLL = 250;

enum ModalTypes {
  AlgomerchModal,
}

export const getSelectCtaCopy = (
  input: string,
  usePriceText?: boolean,
  isMobile?: boolean
) =>
  usePriceText ? (
    <>
      {!isMobile ? "Continue for " : ""}
      <strong>{input}</strong>
    </>
  ) : (
    `Select ${input}`
  );

const ctaTitles: FareDetailsCardCtaTitles = {
  primary: {
    getContent: getSelectCtaCopy,
    type: FareDetailsCardCtaType.Function,
  },
  secondary: undefined,
};

type IOpenModal = ModalTypes | false;

export const getSliceFareDetails = (args: {
  tripDetailsById: ITripDetailsByTripId;
  fareTrips: any;
}): TripDetails | null => {
  const { tripDetailsById, fareTrips } = args;
  if (!fareTrips.length) return null;
  let sliceDetails: TripDetails | null = null;
  sliceDetails = { ...tripDetailsById[fareTrips[0].trip] } as TripDetails;
  sliceDetails.fareDetails = [];
  fareTrips.forEach((fareTrip: any) => {
    const fareDetailByFareId = tripDetailsById[fareTrip.trip].fareDetails.find(
      (fareDetail) => fareDetail.id === fareTrip.fare
    );
    if (fareDetailByFareId && sliceDetails)
      sliceDetails.fareDetails.push(fareDetailByFareId);
  });
  sliceDetails.fareDetails.sort(
    (fare1, fare2) =>
      (fare1?.paxPricings?.[0].pricing.baseAmount.fiat.value || 0) -
      (fare2?.paxPricings?.[0].pricing.baseAmount.fiat.value || 0)
  );

  return sliceDetails as TripDetails;
};

export const FlightList = (props: IFlightListProps) => {
  const {
    flights,
    rewardsKey,
    packagesByOutboundFareSlice,
    isFlightShopLoading,
    largestValueAccount,
    isTripDetailsLoading,
    tripDetailsById,
    fetchPackagesTripDetails,
    setPackagesChosenOutgoingSlice,
    setPackagesFlightShopProgress,
    isOutgoing,
    history,
    outboundFlights,
    returnFlights,
  } = props;
  const { matchesDesktop, matchesLargeDesktop, matchesMobile } =
    useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const [faresToShow, setFaresToShow] = useState<any[]>([]);
  const [expandedFlight, setExpandedFlight] = useState("");
  const [_, setClickedFareId] = useState("");
  const [fareTrips, setFareTrips] = useState([]);

  const [openModal, setOpenModal] = React.useState<IOpenModal>(false);
  const [selectedAlgomerchTag, setSelectedAlgomerchTag] =
    React.useState<AlgomerchTag>(AlgomerchTag.Cheapest);

  const slicesToRender = useMemo(() => {
    if (isOutgoing) {
      return outboundFlights;
    } else {
      console.log("return flights");
      return returnFlights;
    }
  }, [isOutgoing, outboundFlights, returnFlights]);

  const handleClickAlgomerchTag = (tagText: string) => {
    const allTags = Object.keys(AlgomerchTag);
    const selectedTag = allTags.find((tag) =>
      tagText.includes(mapAlgomerchTexts[tag])
    );

    setSelectedAlgomerchTag(
      (selectedTag as AlgomerchTag) ?? AlgomerchTag.Cheapest
    );
  };

  const expandedFareDetails = useMemo(() => {
    const fetchedAllFareDetails = !!fareTrips.length
      ? fareTrips.reduce((hasFetchedFareTrip: boolean, fareTrip: any) => {
          return hasFetchedFareTrip && !!tripDetailsById[fareTrip.trip];
        }, true)
      : false;

    return fetchedAllFareDetails
      ? getSliceFareDetails({
          tripDetailsById,
          fareTrips,
        })
      : null;
  }, [fareTrips, tripDetailsById]);

  useEffect(() => {
    if (isFlightShopLoading) setFaresToShow([]);
  }, [isFlightShopLoading]);

  const setFetchMoreData = () => {
    const newPageSize = faresToShow.length + SHOW_MORE_NUM;
    return setTimeout(
      () => setFaresToShow(slicesToRender.slice(0, newPageSize)),
      500
    );
  };

  useEffect(() => {
    if (slicesToRender.length > 0) {
      setFaresToShow(slicesToRender.slice(0, INITIAL_RESULT_SET_SIZE));
      setExpandedFlight("");
    } else {
      setFaresToShow([]);
    }
    return clearTimeout(setFetchMoreData());
  }, [slicesToRender]);

  // TODO: this should be replaced with actual filters
  const fareClassFilter: FareclassOptionFilter = {
    basic: false,
    standard: false,
    enhanced: false,
    premium: false,
    luxury: false,
  };

  const renderRecommendedFlightInfo = (outbound: any) => {
    const flightSliceId = outbound.slice;
    const flightSlice = flights?.slices[flightSliceId];
    if (!flightSlice) {
      return null;
    }
    return (
      <>
        {renderFlightListInfo(
          outbound.fares[0],
          true,
          flightSlice,
          outbound,
          0,
          true
        )}
        {/* TODO: Move this to components, make common */}
        <Box className={clsx("recommended-flight-list-separator")}>
          <Box className="recommended-flight-list-separator-top-row">
            <Box className="dashed-line" />
            <Typography className="recommended-flight-list-separator-primary-text">
              {RECOMMENDED_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT}
            </Typography>
            <Box className="dashed-line" />
          </Box>
          <Typography className="recommended-flight-list-separator-secondary-text">
            {RECOMMENDED_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT}
          </Typography>
        </Box>
      </>
    );
  };

  const handleFlightSelect = (fareTrips: any) => {
    const uniqueTripIds: string[] = Array.from(
      new Set(fareTrips.map((fareTrip: any) => fareTrip.trip))
    );
    uniqueTripIds.map((fareTrip: string) => {
      fetchPackagesTripDetails(history, fareTrip);
    });

    setFareTrips(fareTrips);
  };

  const handleSliceSelect = (sliceId: string, flight: IFlightListData) => {
    if (sliceId === expandedFlight) {
      setExpandedFlight("");
    } else {
      setExpandedFlight(sliceId);
      handleFlightSelect(flight.fares.map((fare: any) => fare.example));
    }
  };

  const handleFareSelect = (
    flight: any,
    fareId: string,
    _idx: number,
    _limit?: FiatPrice | null
  ) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (flights && expandedFareDetails) {
      const tripId = expandedFareDetails?.id;

      setPackagesChosenOutgoingSlice(
        flight.slice,
        fareId,
        tripId,
        flights.fareSlices[flights.fares[fareId].outbound].fareShelf.value,
        // whenever selecting a different departure flight, reset return ids
        true
      );

      setPackagesFlightShopProgress(FlightShopStep.ChooseReturn);
    }
  };

  const renderFlightListInfo = (
    selectedFare: any,
    showListView: boolean,
    slice: Slice,
    flight: IFlightListData,
    index: number,
    recommendedFlight?: boolean
  ) => {
    // first fare of the outbound flight
    const fareId = selectedFare.example?.fare || selectedFare.id;
    return (
      <Box
        id={slice.id}
        className={clsx(
          "flight-list-item",
          `flight-list-item-${index}`,
          "flight-row",
          {
            "row-view-desktop":
              matchesDesktop || (matchesLargeDesktop && showListView),
            expanded: slice.id === expandedFlight,
          },
          "b2b"
        )}
        key={slice.id}
      >
        <FlightListInfo
          type={FlightCardType.content}
          selectedFare={selectedFare}
          slice={slice}
          flights={flights}
          flight={flight}
          rewardsKey={rewardsKey || ""}
          fareClassFilter={fareClassFilter}
          onAlgomerchClick={(label: string) => {
            handleClickAlgomerchTag(label);
            setOpenModal(ModalTypes.AlgomerchModal);
          }}
          // TODO: use real params here
          maxFlightPriceFilter={10000}
          isRoundTrip={true}
          isOutgoing={true}
          isAgentPortal={isAgentPortal}
          packagesByFareSlice={packagesByOutboundFareSlice}
          recommendedFlight={recommendedFlight}
          largestValueAccount={largestValueAccount}
          onClick={() => {
            // WHAT IS SELECTED FARE CLASS
            handleSliceSelect(slice.id, flight);
            setTimeout(() => {
              const OFFSET = DESKTOP_OFFSET_SCROLL;
              const cardTop =
                document?.getElementById(slice.id)?.getBoundingClientRect()
                  .top || 0;
              window.scrollBy({
                top: (cardTop as number) - OFFSET,
                behavior: "smooth",
              });
            }, 100);
          }}
          onFareClick={(fareId: string) => {
            setClickedFareId(fareId);
          }}
        />
        {matchesMobile && slice.id === expandedFlight && (
          <MobilePopoverCard
            headerElement={
              <Header
                className="mobile-flight-details-header-container"
                left={
                  <BackButton
                    className="back-button-icon"
                    onClick={() => {
                      setExpandedFlight("");
                    }}
                  />
                }
                center={
                  <Typography
                    variant="body1"
                    className="mobile-flight-details-header"
                  >
                    {"Choose Fare"}
                  </Typography>
                }
                isMobile={true}
                fullWidth={true}
              />
            }
            open={true}
            fullScreen={true}
            className="mobile-flight-details-container"
            contentClassName="mobile-flight-details-container-content"
            centered={true}
          >
            {(!expandedFareDetails || isTripDetailsLoading) && (
              <LoadingIndicator
                className="packages-flight-shop-details-loading-indicator"
                indicatorSize={"small"}
                indicator={B2BSpinner}
                message={LOADING_FLIGHT_DETAILS_STRING}
              />
            )}
            {expandedFareDetails && !isTripDetailsLoading && (
              <FlightDetails
                isOutgoing={true}
                selectedFareId={fareId}
                onFareClick={(fareId, limit) => {
                  handleFareSelect(flight, fareId, index, limit);
                }}
                tripDetails={expandedFareDetails}
                rewardsKey={undefined}
                departureDate={undefined}
                returnDate={undefined}
                airports={{}}
                openMobileFlightDetailsModal={false}
                setOpenMobileFlightDetailsModal={() => {}}
                isSpiritOrFrontierAirlinesSelected={false}
                onAlgomerchClick={(label: string) => {
                  handleClickAlgomerchTag(label);
                  setOpenModal(ModalTypes.AlgomerchModal);
                }}
                ctaTitles={ctaTitles}
                showFareDetailsTitle={true}
                isMobile={matchesMobile}
              />
            )}
          </MobilePopoverCard>
        )}
        {slice.id === expandedFlight &&
          expandedFareDetails &&
          !isTripDetailsLoading && (
            <FlightDetails
              isOutgoing={true}
              selectedFareId={fareId}
              onFareClick={(fareId, limit) => {
                handleFareSelect(flight, fareId, index, limit);
              }}
              tripDetails={expandedFareDetails}
              rewardsKey={undefined}
              departureDate={undefined}
              returnDate={undefined}
              airports={{}}
              openMobileFlightDetailsModal={false}
              setOpenMobileFlightDetailsModal={() => {}}
              isSpiritOrFrontierAirlinesSelected={false}
              onAlgomerchClick={(label: string) => {
                handleClickAlgomerchTag(label);
                setOpenModal(ModalTypes.AlgomerchModal);
              }}
              ctaTitles={ctaTitles}
              showFareDetailsTitle={true}
              isMobile={matchesMobile}
            />
          )}
        {slice.id === expandedFlight &&
          (!expandedFareDetails || isTripDetailsLoading) && (
            <LoadingIndicator
              className="packages-flight-shop-details-loading-indicator"
              indicatorSize={"small"}
              indicator={B2BSpinner}
              message={LOADING_FLIGHT_DETAILS_STRING}
            />
          )}
      </Box>
    );
  };

  const listRef = React.useRef<ReactList | null>(null);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <Box className={clsx("packages-flight-list-root")}>
      {!isFlightShopLoading &&
        flights &&
        packagesByOutboundFareSlice &&
        faresToShow.length > 0 &&
        (matchesMobile ? (
          <div ref={divRef} className="availability-list">
            {renderRecommendedFlightInfo(faresToShow[0])}
            <ReactList
              ref={listRef}
              itemRenderer={(index: number) => {
                const currentFare = faresToShow[index];

                const { slice: flightSliceId } = currentFare;
                const flightSlice = flights?.slices[flightSliceId];
                const isListView = !flightSlice?.domestic;
                if (!flightSlice) {
                  // itemRenderer does not allow for returning of undefined/null
                  return (
                    <Box
                      display="none"
                      // key={`${fare.example?.fare || fare.id}-none`}
                    />
                  );
                }

                return renderFlightListInfo(
                  currentFare.fares[0],
                  isListView,
                  flightSlice,
                  currentFare,
                  index
                );
              }}
              length={faresToShow.length}
              type="variable"
            />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={faresToShow.length}
            next={setFetchMoreData}
            hasMore={faresToShow.length < slicesToRender.length}
            loader={
              <Box className="loading-flights">
                <B2BSpinner classes={["loading-flights-bunny"]} />
              </Box>
            }
          >
            {/* TODO: This should use actual recommended flight once we're unblocked */}
            {renderRecommendedFlightInfo(faresToShow[0])}
            {faresToShow.map((outbound: any, index: number) => {
              // TODO: Filter for not recommended flight
              const flightSliceId = outbound.slice;
              const flightSlice = flights.slices[flightSliceId];
              if (!flightSlice) {
                return null;
              }
              // TODO: Use real params
              const isListView = true;
              return renderFlightListInfo(
                outbound.fares[0],
                isListView,
                flightSlice,
                outbound,
                index
              );
            })}
          </InfiniteScroll>
        ))}
      <FlightAlgomerchModal
        selectedCategory={selectedAlgomerchTag}
        setSelectedCategory={setSelectedAlgomerchTag}
        openModal={openModal === ModalTypes.AlgomerchModal}
        onClose={() => setOpenModal(false)}
      />
    </Box>
  );
};
