import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import { DesktopPackagesBookWorkflowConnectorProps } from "./container";
import { ButtonWrap, CheckoutStepper, TravelerSelectStep } from "halifax";
import clsx from "clsx";
import { PATH_HOME } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import { CheckoutStepperProps } from "halifax/build/CheckoutStepper/component";
import { HEADER_MAIN_TEXT, HEADER_SUB_TEXT } from "./textConstants";
import { PackagesShopProgressBar, PackageDetailsPanel } from "../";
import { FlightBookPassengerSelection } from "../";

const PLACEHOLDER_STEPS: CheckoutStepperProps["steps"] = [
  { name: "Who's flying?", status: 1 },
  { name: "Who's checking in?", status: 0 },
  { name: "Seat selection", status: 0 },
  { name: "Rewards & payment", status: 0 },
];

export interface IDesktopPackagesBookWorkflowProps
  extends RouteComponentProps,
    DesktopPackagesBookWorkflowConnectorProps {}

export const DesktopPackagesBookWorkflow = ({
  history,
}: IDesktopPackagesBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);

  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    React.useState<TravelerSelectStep>(TravelerSelectStep.Main);

  return (
    <Box className="desktop-packages-book-workflow-root">
      <CheckoutStepper
        steps={PLACEHOLDER_STEPS}
        headerCopy={HEADER_MAIN_TEXT}
        subHeaderCopy={HEADER_SUB_TEXT}
        logo={
          <ButtonWrap
            className="logo"
            onClick={() => {
              history.push(PATH_HOME);
            }}
          >
            {clientContext.logo}
          </ButtonWrap>
        }
        className={clsx("b2b", "combined-step")}
      />

      <Box className="packages-book-progress-bar-wrapper">
        <PackagesShopProgressBar />
      </Box>

      <Box className="packages-book-body-root">
        <Box className="packages-book-body-wrapper">
          <Box className="packages-book-body-left">
            <PackageDetailsPanel />

            <FlightBookPassengerSelection
              progress={travelerWorkflowStep}
              setProgress={setTravelerWorkflowStep}
            />
          </Box>
          <Box className="packages-book-body-right"></Box>
        </Box>
      </Box>
    </Box>
  );
};
