import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import clsx from "clsx";
import { useExperimentIsVariant } from "@capone/experiments";
import { PolicyModalButton, PolicyDetailsModal } from "halifax";
import { ClientContext } from "../../../../App";
import { PackagesSearchControl } from "../../../search/components/PackagesSearchControl";
import "./styles.scss";
import { AvailabilityFilter } from "../AvailabilityFilter";
import { PackagesSearchButton } from "../../../search/components/PackagesSearchControl/components";
import { AvailabilitySearchControlConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { transformToStringifiedAvailabilityQuery } from "../../utils/queryStringHelpers";
import { IIdLodgings } from "redmond";
import { PATH_HOTELS_AVAILABILITY } from "../../../../utils/paths";
import { PackagesShopProgressBar } from "../../../book/components";

interface IAvailabilitySearchControlProps
  extends AvailabilitySearchControlConnectorProps {
  displaySearchOnChange?: boolean;
}

export const AvailabilitySearchControl = (
  props: IAvailabilitySearchControlProps
) => {
  const {
    displaySearchOnChange = true,
    fetchInitialPackagesAvailability,
    setMapBound,
    departureDate,
    returnDate,
    searchLocation,
    travelers,
    origin,
    stopsOptions,
    setViewHotelsNearLocation,
    areRequestParametersChanged,
    resetFilters,
    mapBounds,
  } = props;

  const [isPolicyModalOpen, setIsPolicyModalOpen] = React.useState(false);

  const { policies } = useContext(ClientContext);

  const onShowPolicyDetailsModal = () => {
    setIsPolicyModalOpen(true);
    // trackEvent({
    //   eventName: VIEWED_POLICY_MODAL,
    //   properties: {
    //     type: POLICY_MODAL,
    //     entry_point: ModalScreens.HOTELS_AVAILABILITY,
    //     funnel: "hotels",
    //   },
    // });
  };

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  return (
    <Box className="packages-availability-search-container">
      <PackagesShopProgressBar />
      <Box
        className={clsx("packages-availability-search-control", {
          "show-policy-button": isPolicyDescriptorsEnabled,
        })}
      >
        <Box className="search-components-section">
          <PackagesSearchControl showSearchButton={false} rerunOnStopsToggle />
        </Box>
        <Box className="sort-filter-section">
          {(!displaySearchOnChange || areRequestParametersChanged) &&
            origin &&
            departureDate &&
            returnDate && (
              <PackagesSearchButton
                className={clsx("packages-search-control-button", "b2b")}
                message={textConstants.SEARCH_AGAIN}
                onClick={(history) => {
                  history.push(
                    `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
                      origin?.id?.code?.code,
                      (searchLocation?.id as IIdLodgings).lodgingSelection
                        .searchTerm,
                      departureDate,
                      returnDate,
                      travelers.adultsCount,
                      travelers.children,
                      travelers.infants,
                      stopsOptions
                    )}`
                  );
                  mapBounds && setMapBound(null);
                  setViewHotelsNearLocation(null);
                  resetFilters();
                  fetchInitialPackagesAvailability(history);
                }}
              />
            )}
          <AvailabilityFilter />
        </Box>
      </Box>
      {isPolicyDescriptorsEnabled && (
        <>
          <Box className="policy-modal-button-container">
            <PolicyModalButton
              underline={false}
              onClick={onShowPolicyDetailsModal}
            />
          </Box>
          <PolicyDetailsModal
            policies={policies}
            isOpen={isPolicyModalOpen}
            setIsOpen={setIsPolicyModalOpen}
            productType="hotel"
          />
        </>
      )}
    </Box>
  );
};
