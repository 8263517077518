import { assign } from "xstate";
import { ParentState } from "@capone/checkout";
import { Payment } from "@b2bportal/purchase-api";
import { PackagesMachineContext } from "../types";
import { CardPayment } from "@b2bportal/card-api";
import { Currency } from "redmond";

export const setPaymentFulfillParams = assign(
  (ctx: PackagesMachineContext, _event) => {
    // let awardsPayment: { type: Payment; value: RewardsPayment } | null = null;
    let cardPayment: { type: Payment; value: CardPayment } | null = null;
    // let walletCreditPayment: {
    //   type: Payment;
    //   value: WalletCreditPayment;
    // } | null = null;

    const totalPricing =
      ctx[ParentState.cartQuote].quoteBreakdown?.productTotal ||
      ctx[ParentState.cartQuote].quoteBreakdown?.sellAt ||
      ctx[ParentState.cartQuote].quoteBreakdown?.balance;

    // // travel wallet credit payment params
    // if (ctx.walletPayment?.creditPaymentAmount?.value) {
    //   const creditAmount = ctx.walletPayment.creditPaymentAmount;
    //   walletCreditPayment = {
    //     type: Payment.TravelWalletCredit,
    //     value: {
    //       paymentAmount: {
    //         amount: creditAmount.value,
    //         currency: creditAmount.currencyCode,
    //       },
    //     },
    //   };
    // }

    // awards points payment params
    // if (ctx[ParentState.awardsPayment].selectedAccount?.cardAccountId) {
    //   const amountToPayAwardsCash =
    //     ctx[ParentState.awardsPayment].awardsCashAmountToApply;
    //   const amountToPayAwards =
    //     ctx[ParentState.awardsPayment].awardsAmountToApply;
    // awardsPayment = {
    //   type: Payment.Rewards,
    //   value: {
    //     cardAccountId:
    //       ctx[ParentState.awardsPayment].selectedAccount.cardAccountId,
    //     cashAmount: {
    //       amount: amountToPayAwardsCash,
    //       currency: totalPricing?.fiat.currencyCode,
    //     },
    //     pointsAmount: {
    //       currency:
    //         ctx[ParentState.awardsPayment].selectedAccount?.awardsBalance
    //           ?.currency,
    //       value: amountToPayAwards,
    //     },
    //   },
    // };
    // }

    // card payment params
    if (ctx[ParentState.cardPayment]?.selectedPaymentMethodId) {
      // This must have been rounded to 2 decimal place.
      // const amountToPayAwardsCash =
      //   ctx[ParentState.awardsPayment].awardsCashAmountToApply || 0;
      // const creditAmount = ctx.walletPayment?.creditPaymentAmount?.value || 0;

      cardPayment = {
        type: Payment.Card,
        value: {
          paymentAmount: {
            amount:
              Math.round(
                (totalPricing?.fiat.value || 0) * 100
                // - amountToPayAwardsCash * 100
                // - creditAmount * 100
              ) / 100,
            currency: totalPricing?.fiat.currencyCode || Currency.USD,
          },
          paymentId: ctx[ParentState.cardPayment].selectedPaymentMethodId,
        },
      };
    }

    ctx[ParentState.cartFulfill].fulfillRequestPayments = [];
    // if (walletCreditPayment) {
    //   ctx[ParentState.cartFulfill].fulfillRequestPayments.push(
    //     walletCreditPayment
    //   );
    // }

    // if (awardsPayment) {
    //   ctx[ParentState.cartFulfill].fulfillRequestPayments.push(awardsPayment);
    // }

    if (cardPayment && (cardPayment?.value.paymentAmount.amount || 0) > 0) {
      ctx[ParentState.cartFulfill].fulfillRequestPayments.push(cardPayment);
    }

    return ctx;
  }
);
