import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import {
  RoomInfoProducts,
  CorpRoomInfoProducts,
  RoomProduct,
  CorpRoomProduct,
} from "redmond";
import { PackageHotelShopCallState } from "../state";

export const getPackageSelectedLodging = (state: IStoreState) =>
  state.packageHotelShop.selectedLodging;

export const getHotelShopDateRange = (state: IStoreState) =>
  state.packageHotelShop.dateRange;

export const getSelectedPackageByLodgingId = (state: IStoreState) =>
  state.packageHotelShop.selectedPackageByLodgingId;

export const getHotelShopCallState = (state: IStoreState) =>
  state.packageHotelShop.hotelShopCallState;

export const getPackageLodgingRoomInfoProducts = (state: IStoreState) =>
  state.packageHotelShop.roomInfoProducts;

export const getCheapestLodgingRoomProduct = createSelector(
  getPackageLodgingRoomInfoProducts,
  (roomInfoProducts) => roomInfoProducts[0]?.products[0]
);

export const getPackageRatesById = (state: IStoreState) =>
  state.packageHotelShop.packagesByRateId;

export const getHotelShopChosenRoomInfoIndex = (state: IStoreState) =>
  state.packageHotelShop.chosenRoomInfoIndex;

export const getHotelShopChosenProductIndex = (state: IStoreState) =>
  state.packageHotelShop.chosenProductIndex;

export const getHotelShopChosenRoomInfo = createSelector(
  [getPackageLodgingRoomInfoProducts, getHotelShopChosenRoomInfoIndex],
  (
    roomInfoProducts,
    chosenRoomInfoIndex
  ): RoomInfoProducts | CorpRoomInfoProducts | null => {
    if (chosenRoomInfoIndex !== null) {
      return roomInfoProducts[chosenRoomInfoIndex] ?? null;
    }

    return null;
  }
);

export const getHotelShopChosenProduct = createSelector(
  [
    getPackageLodgingRoomInfoProducts,
    getHotelShopChosenRoomInfoIndex,
    getHotelShopChosenProductIndex,
  ],
  (
    roomInfoProducts,
    chosenRoomInfoIndex,
    chosenProductIndex
  ): RoomProduct | CorpRoomProduct | null => {
    if (chosenRoomInfoIndex !== null && chosenProductIndex !== null) {
      return (
        roomInfoProducts[chosenRoomInfoIndex]?.products[chosenProductIndex] ??
        null
      );
    }

    return null;
  }
);

export const getDistanceFromLocation = (state: IStoreState) =>
  state.packageHotelShop.distanceFromLocation;

export const getDistanceFromLocationCategories = (state: IStoreState) =>
  state.packageHotelShop.distanceFromLocationCategories;

export const getDistanceFromLocationCategoriesLoading = (state: IStoreState) =>
  state.packageHotelShop.distanceFromLocationCategoriesLoading;

export const isMissingProductInfo = createSelector(
  getHotelShopCallState,
  getPackageLodgingRoomInfoProducts,
  (hotelShopCallState, roomInfoProducts) => {
    return (
      hotelShopCallState === PackageHotelShopCallState.Success &&
      roomInfoProducts.length === 0
    );
  }
);

export const hasHotelShopFailed = createSelector(
  getHotelShopCallState,
  isMissingProductInfo,
  (hotelShopCallState, missingProductInfo) => {
    return (
      hotelShopCallState === PackageHotelShopCallState.Failed ||
      missingProductInfo
    );
  }
);

export const hotelShopProgressSelector = (state: IStoreState) =>
  state.packageHotelShop.progress;

export const getHotelShopCancellationSummary = (state: IStoreState) =>
  state.packageHotelShop.cancellationSummary;

export const getHotelShopCallError = (state: IStoreState) =>
  state.packageHotelShop.hotelShopCallError;
