import {
  DateRange,
  CorpRoomInfoProducts,
  RoomInfoProducts,
  PackagedLodging,
  CorpLodging,
  Lodging,
  PackagedRoom,
  ICategorizedResponse,
  IResult,
  CancellationSummary,
} from "redmond";

export interface IPackageHotelShopState {
  selectedLodging: Lodging | CorpLodging | null;
  selectedPackageByLodgingId?: PackagedLodging;
  dateRange: DateRange | null;
  roomInfoProducts: RoomInfoProducts[] | CorpRoomInfoProducts[];
  chosenRoomInfoIndex: number | null;
  chosenProductIndex: number | null;
  hotelShopCallState: PackageHotelShopCallState;
  hotelShopCallError: PackageHotelShopCallError | null;
  progress: PackageHotelShopStep;
  packagesByRateId: { [key: string]: PackagedRoom };
  distanceFromLocation: IResult | null;
  distanceFromLocationCategories: ICategorizedResponse[];
  distanceFromLocationCategoriesLoading: boolean | null;
  cancellationSummary: CancellationSummary | null;
}

export enum PackageHotelShopStep {
  HotelInfo = "hotel-info-step",
  ChooseRoom = "choose-room-step",
}

export enum PackageHotelShopCallState {
  NotCalled = "NotCalled",
  InProcess = "InProcess",
  Success = "Success",
  Failed = "Failed",
  FailureAcknowledged = "FailureAcknowledged",
}

export enum PackageHotelShopCallError {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
}
