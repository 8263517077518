import React, { useContext, useState } from "react";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
  useDeviceTypes,
  getDateTimeWithFormat,
  VoidWindowNotice,
} from "halifax";
import {
  IPerson,
  PersonId,
  ADD_FREQUENT_FLYER,
  ITravelerStepErrors,
  CLICKED_ADD_TRAVELER,
  ClickedAddTravelerProperties,
  Gender,
  Assistance,
} from "redmond";
import clsx from "clsx";
import dayjs from "dayjs";
import { isEqual } from "lodash-es";

import "./styles.scss";
import { FlightBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { InfantSeatPickerModal } from "../InfantSeatPickerModal";
import { PassportAwarenessCard } from "../PassportAwarenessCard/component";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import {
  FlightPassengerSelectors,
  // FlightSelectors,
  ParentState,
  // useCheckoutStateSelector,
  useChildMachineSelector,
  useChildMachineState,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";

export interface IFlightBookPassengerSelectionProps
  extends FlightBookPassengerSelectionConnectorProps {
  progress: TravelerSelectStep;
  setProgress: (progress: TravelerSelectStep) => void;
  onProgressChange?: (step: TravelerSelectStep) => void;
  onContinue?: (travelersChanged?: boolean) => void;
  onGoBack?: (travelersChanged?: boolean) => void;
  className?: string;
  selectionScreenHeaderElement?: JSX.Element;
  onReviewStep?: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  combinedStep?: boolean;
  useLocalIds?: boolean;
  showErrors?: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
  saveButtonClicked?: boolean;
  localTravelerIds?: String[];
  setLocalTravelerIds?: (ids: string[]) => void;
  localLapInfantIds?: String[];
  setLocalLapInfantIds?: (ids: string[]) => void;
  showVoidWindowNotice?: boolean;
  showPassportAwareness?: boolean;
}

export const FlightBookPassengerSelection = (
  props: IFlightBookPassengerSelectionProps
) => {
  const {
    className,
    progress,
    setProgress,
    onProgressChange,
    onContinue,
    onGoBack,
    selectionScreenHeaderElement,
    onReviewStep,
    isMobile,
    disabled,
    combinedStep,
    useLocalIds,
    showErrors,
    setShowErrors,
    saveButtonClicked,
    localLapInfantIds,
    setLocalLapInfantIds,
    localTravelerIds,
    setLocalTravelerIds,
    showVoidWindowNotice,
    showPassportAwareness,
  } = props;

  const [, passengerChildMachineSend] = useChildMachineState<
    TEvent,
    PackagesMachineContext
  >(ParentState.passengerInformation);

  const travelers = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getUserPassengers
  );

  const allSelectedTravelerIds = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getAllSelectedPassengerIds
  );

  const selectedTravelerIds = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getSelectedPassengerIds
  );

  const selectedLapInfantIds = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getSelectedLapInfantIds
  );

  const passengersLoading = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getIsPassengerInformationLoading
  );

  const hasError = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerErrorOpen
  );

  // TODO replace once trip details are populated in checkout machine
  // const tripDetails = useCheckoutStateSelector(FlightSelectors.getTripDetails);
  const tripFinalFlightDate =
    // tripDetails.slices[tripDetails.slices?.length - 1].departureTime ||
    new Date().toDateString();

  const { matchesMobile } = useDeviceTypes();
  const [currentInfantToSelectSeat, setCurrentInfantToSelectSeat] = useState<
    string | boolean
  >(false);
  const expState = useExperiments();
  const travelersChanged =
    !isEqual(selectedTravelerIds, localTravelerIds) ||
    !isEqual(selectedLapInfantIds, localLapInfantIds);

  const handleSelectPassenger = (
    passengerId: PersonId,
    singleTravelerWorkflow?: boolean
  ) => {
    if (hasError) {
      passengerChildMachineSend(Event.CLEAR_PASSENGER_INFORMATION_ERROR);
    }
    if (!allSelectedTravelerIds.includes(passengerId)) {
      // trackEvent({
      //   eventName: CHOOSE_TRAVELER,
      //   properties: chooseTravelerProperties,
      // });
    }
    const selectedPassenger = travelers.find((p) => p.id === passengerId);
    if (selectedPassenger) {
      passengerChildMachineSend({
        type: Event.SET_CURRENT_PASSENGER,
        passenger: selectedPassenger as IPerson,
      });
    }
    passengerChildMachineSend({
      type: Event.SELECT_PASSENGER,
      passengerId,
      singleTravelerWorkflow,
    });
  };

  const handleUpdatePassenger = (passenger: IPerson) => {
    if (!allSelectedTravelerIds.includes(passenger.id)) {
      passengerChildMachineSend({
        type: Event.SET_CURRENT_PASSENGER,
        passenger,
      });
    }
    passengerChildMachineSend({
      type: Event.UPDATE_PASSENGER,
      person: passenger,
    });
  };

  const handleDeletePassenger = (personId: string) => {
    passengerChildMachineSend({ type: Event.DELETE_PASSENGER, personId });
  };

  const { sessionInfo } = useContext(ClientContext);

  const getIsLapInfant = (traveler: IPerson) => {
    const ageAtBoardingTime = dayjs(tripFinalFlightDate).diff(
      getDateTimeWithFormat(traveler.dateOfBirth, "YYYY-MM-DD"),
      "year"
    );
    return ageAtBoardingTime < 2;
  };

  const handleContinue = () => {
    if (useLocalIds && travelersChanged) {
      setLocalTravelerIds && setLocalTravelerIds(selectedTravelerIds);
      setLocalLapInfantIds && setLocalLapInfantIds(selectedLapInfantIds);
    }
    onContinue && onContinue(travelersChanged);
  };

  const handleGoBack = () => {
    if (useLocalIds && travelersChanged) {
      setLocalTravelerIds && setLocalTravelerIds(selectedTravelerIds);
      setLocalLapInfantIds && setLocalLapInfantIds(selectedLapInfantIds);
    }
    onGoBack && onGoBack(travelersChanged);
  };

  return (
    <>
      {passengersLoading ? (
        <LoadingIndicator
          className="flight-book-passenger-selection-loading-indicator"
          indicatorSize="small"
          indicator={B2BSpinner}
          message={constants.UPDATE_TEXT}
        />
      ) : (
        <TravelerSelectWorkflow
          showAdditionalInfoSection
          showFrequentFlyerSection
          showGenderField
          showNationalityField
          className={clsx("flight-book-passenger-selection-root", className, {
            "combined-step": combinedStep,
          })}
          travelers={travelers.map((traveler) => ({
            ...traveler,
            gender: Gender[traveler.gender],
            assistance: traveler.assistance.map(
              (assistance) => Assistance[assistance]
            ),
          }))}
          progress={progress}
          setProgress={setProgress}
          userInfo={sessionInfo?.userInfo}
          disabled={disabled}
          titles={{
            travelerInfoTitle: matchesMobile
              ? onReviewStep
                ? constants.TRAVELER_INFO_TEXT
                : constants.ADD_TRAVELERS_TEXT
              : constants.TRAVELER_INFO_TITLE_UPDATED,
            travelerInfoSubtitle: constants.TRAVELER_INFO_SUBTITLE,
            frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
            additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
            adultTitle: constants.ADULT_TITLE,
            childTitle: constants.CHILD_TITLE,
            infantSeatTitle: constants.INFANT_SEAT_TITLE,
            infantLapTitle: constants.INFANT_LAP_TITLE,
            addTravelers: constants.ADD_TRAVELERS_TEXT_UPDATED,
            travelerInfoFormSubtitle: constants.ADD_TRAVELERS_SUBTITLE,
            travelerInfoSectionTitle: constants.TRAVELER_INFO_TEXT,
            passportTitle: constants.PASSPORT_TITLE,
            passportSubtitle: constants.PASSPORT_SUBTITLE,
            passportSubtitle2: constants.PASSPORT_SUBTITLE_2,
            completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
            completeTravelerProfileSubtitle:
              constants.COMPLETE_PROFILE_SUBTITLE,
          }}
          selectedTravelerIds={[
            ...selectedTravelerIds,
            ...selectedLapInfantIds,
          ]}
          handleSelectTraveler={handleSelectPassenger}
          handleUpdatePassenger={(
            traveler: IPerson
            // hideProfileAction: ToggleActions,
            // entryPoint?: string,
            // updatePassport?: boolean,
            // isNewTraveler?: boolean
          ) => {
            const freqFlyerKeys = Object.keys(traveler.frequentFlyer);
            if (
              freqFlyerKeys.length > 0 &&
              traveler.frequentFlyer !==
                travelers.find((t) => t.id === traveler.id)?.frequentFlyer
            ) {
              trackEvent({
                eventName: ADD_FREQUENT_FLYER,
                properties: {
                  frequent_flyer_program:
                    freqFlyerKeys.length > 0
                      ? freqFlyerKeys[freqFlyerKeys.length - 1]
                      : "",
                },
              });
            }
            if (getIsLapInfant(traveler)) {
              handleUpdatePassenger(traveler);
              setCurrentInfantToSelectSeat(traveler.id);
            } else {
              handleUpdatePassenger(traveler);
            }
          }}
          handleDeletePassenger={handleDeletePassenger}
          isMobile={matchesMobile}
          onProgressChange={onProgressChange}
          onContinue={handleContinue}
          onGoBack={handleGoBack}
          selectionScreenHeaderElement={selectionScreenHeaderElement}
          buttonClassName="b2b"
          wrapButton={isMobile && !onReviewStep}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          saveButtonClicked={saveButtonClicked}
          errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
          isFlights={true}
          bottomContent={
            showVoidWindowNotice || showPassportAwareness ? (
              <>
                {showVoidWindowNotice && <VoidWindowNotice />}
                {showPassportAwareness && <PassportAwarenessCard />}
              </>
            ) : undefined
          }
          showPassportSection
          requireNationality
          onClickAddNewTraveler={() => {
            trackEvent({
              eventName: CLICKED_ADD_TRAVELER,
              properties: addTrackingProperties(expState.trackingProperties, {
                entry_type: "checkout",
              } as ClickedAddTravelerProperties),
            });
          }}
          tenant={config.TENANT}
          trackEvent={trackEvent}
          setSelectedTravelerIds={() => {}}
        />
      )}
      <InfantSeatPickerModal
        currentInfantToSelectSeat={currentInfantToSelectSeat}
        setCurrentInfantToSelectSeat={setCurrentInfantToSelectSeat}
        handleSelectTraveler={handleSelectPassenger}
      />
    </>
  );
};
