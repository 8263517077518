import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { DesktopFlightShop } from "./component";
import { fetchPackagesFlightShop } from "../../actions/actions";

import { getDestination, getOrigin } from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    origin: getOrigin(state),
    destination: getDestination(state),
  };
};

export const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
  fetchPackagesFlightShop: fetchPackagesFlightShop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type DesktopShopConnectorProps = ConnectedProps<typeof connector>;

export interface IDesktopFlightShopProps
  extends DesktopShopConnectorProps,
    RouteComponentProps {}

export const ConnectedDesktopFlightShop = withRouter(
  connector(DesktopFlightShop)
);
