import React, { useState, useEffect } from "react";
import {
  ActionLink,
  B2BSpinner,
  CloseButtonIcon,
  DesktopPopupModal,
  MobilePopoverCard,
  useDeviceTypes,
} from "halifax";
import { FiatPrice, FareBrand } from "redmond";
import { Box, Button, Typography, Radio } from "@material-ui/core";
import clsx from "clsx";

import { InfantSeatPickerModalConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import {
  FlightPassengerSelectorsV2,
  FlightSelectors,
  ParentState,
  useCheckoutStateSelector,
  useChildMachineState,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";

export interface IInfantSeatPickerModalProps
  extends InfantSeatPickerModalConnectorProps {
  currentInfantToSelectSeat: string | boolean;
  setCurrentInfantToSelectSeat: React.Dispatch<
    React.SetStateAction<string | boolean>
  >;
  handleSelectTraveler: (travelerId: string) => void;
}

enum SeatType {
  OnLap = "OnLap",
  OwnSeat = "OwnSeat",
}

const formatFiatValue = (value: number) =>
  value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
const getTotalPrice = (pricing: FiatPrice[]) =>
  pricing.reduce((sum, cur) => sum + cur.value, 0);

export const InfantSeatPickerModal = ({
  currentInfantToSelectSeat,
  setCurrentInfantToSelectSeat,
  handleSelectTraveler,
}: IInfantSeatPickerModalProps) => {
  const [, passengerChildMachineSend] = useChildMachineState<
    TEvent,
    PackagesMachineContext
  >(ParentState.passengerInformation);

  const fareDetails = useCheckoutStateSelector(
    FlightSelectors.getSelectedFareDetails
  );

  const tripPricing = useCheckoutStateSelector(FlightSelectors.getTripPricing);

  const tripPricingLoading = useCheckoutStateSelector(
    FlightPassengerSelectorsV2.getIsInfantSeatModalLoading
  );

  const { matchesMobile } = useDeviceTypes();
  const [infantSeatPricing, setInfantSeatPricing] = useState({
    lapSeatPricing: fareDetails?.paxPricings!.find(
      (pax) => pax.paxType === "INF"
    ),
    ownSeatPricing: fareDetails?.paxPricings!.find(
      (pax) => pax.paxType === "INS"
    ),
  });
  const brand: FareBrand | undefined =
    fareDetails &&
    fareDetails.slices.length > 0 &&
    fareDetails.slices[0].fareDetails.segments.length > 0
      ? fareDetails?.slices[0].fareDetails.segments[0].brand
      : undefined;
  const isTravelFusion = brand?.travelFusionFareBrand ? true : false;

  const closePopup = () => setCurrentInfantToSelectSeat(false);

  // useEffect(() => {
  //   if (tripPricingCallState === CallState.Failed) {
  //     closePopup();
  //   }
  // }, [tripPricingCallState]);

  useEffect(() => {
    setInfantSeatPricing({
      lapSeatPricing: fareDetails?.paxPricings!.find(
        (pax) => pax.paxType === "INF"
      ),
      ownSeatPricing: fareDetails?.paxPricings!.find(
        (pax) => pax.paxType === "INS"
      ),
    });
  }, [fareDetails]);

  const totalPricing = {
    lapSeatTotal: infantSeatPricing?.lapSeatPricing
      ? getTotalPrice([
          infantSeatPricing.lapSeatPricing.pricing.baseAmount.fiat,
          infantSeatPricing.lapSeatPricing.pricing.taxAmount.fiat,
        ])
      : undefined,
    ownSeatPricing: infantSeatPricing?.ownSeatPricing
      ? getTotalPrice([
          infantSeatPricing.ownSeatPricing.pricing.baseAmount.fiat,
          infantSeatPricing.ownSeatPricing.pricing.taxAmount.fiat,
        ])
      : undefined,
  };

  const currencySymbol =
    tripPricing?.totalPricing.total.fiat.currencySymbol || "$";

  const InfantSeatPickerModalContent = () => {
    const [seatType, setSeatType] = useState<SeatType>(SeatType.OnLap);

    const onContinue = () => {
      if (typeof currentInfantToSelectSeat === "string") {
        handleSelectTraveler(currentInfantToSelectSeat);
      }
      passengerChildMachineSend({
        type: Event.ON_INFANT_MODAL_CONTINUE,
        seatType,
      });
      closePopup();
    };

    return (
      <>
        {tripPricingLoading && (
          <Box className="loading-container">
            <B2BSpinner />
            <Typography className="subheading">
              {constants.LOADING_TEXT}
            </Typography>
          </Box>
        )}
        {!tripPricingLoading && (
          <Box className="lap-infant-seat-picker-container">
            <Typography className="heading">
              {constants.INFANT_ONBOARD}
            </Typography>
            <Typography className="subheading">
              {constants.SELECT_SEAT_TYPE}
            </Typography>
            <SelectRow
              label={constants.ON_LAP}
              description={constants.ON_LAP_DESCRIPTION}
              amount={totalPricing.lapSeatTotal}
              selected={seatType === SeatType.OnLap}
              onClick={() => setSeatType(SeatType.OnLap)}
              currencySymbol={currencySymbol}
            />
            {!isTravelFusion && (
              <SelectRow
                label={constants.OWN_SEAT}
                description={constants.OWN_SEAT_DESCRIPTION}
                amount={totalPricing.ownSeatPricing}
                selected={seatType === SeatType.OwnSeat}
                onClick={() => setSeatType(SeatType.OwnSeat)}
                currencySymbol={currencySymbol}
              />
            )}
            {(!fareDetails?.paxPricings!.find((pax) => pax.paxType === "INS") ||
              !fareDetails?.paxPricings!.find(
                (pax) => pax.paxType === "INF"
              )) && (
              <Typography
                className="infant-unavail-pricing-text"
                variant="body2"
              >
                {constants.INFANT_PRICING_UNAVAIL_TEXT}
              </Typography>
            )}
            <Button
              onClick={onContinue}
              className="continue-button"
              variant="contained"
            >
              {constants.CONTINUE}
            </Button>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {matchesMobile ? (
        <MobilePopoverCard
          open={Boolean(currentInfantToSelectSeat)}
          onClose={closePopup}
          centered={true}
          className="infant-seat-picker-modal"
          contentClassName="infant-seat-picker-popover-content"
          topRightButton={
            <ActionLink
              className="filter-close-button"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={closePopup}
            />
          }
        >
          <InfantSeatPickerModalContent />
        </MobilePopoverCard>
      ) : (
        <DesktopPopupModal
          open={Boolean(currentInfantToSelectSeat)}
          onClose={closePopup}
          className="lap-infant-seat-select-popup-root"
          invisibleBackdrop={false}
        >
          <InfantSeatPickerModalContent />
        </DesktopPopupModal>
      )}
    </>
  );
};

interface ISelectRow {
  selected: boolean;
  label: string;
  amount?: number;
  description: string;
  onClick: () => void;
  currencySymbol: string;
}

const SelectRow = ({
  selected,
  label,
  amount,
  description,
  onClick,
  currencySymbol,
}: ISelectRow) => {
  const labelSubText =
    amount === undefined
      ? "Price available at checkout"
      : amount === 0
      ? constants.FREE_DESCRIPTION
      : `${currencySymbol}${formatFiatValue(amount)} ${description}`;
  return (
    <Box className="select-row">
      <Radio
        className={clsx("radio", { selected })}
        checked={selected}
        onClick={() => onClick()}
      />
      <Box
        className={clsx("select-row-text-container", { selected })}
        onClick={onClick}
      >
        <Typography>
          <strong>{label}</strong>
          {` - ${labelSubText}`}
        </Typography>
      </Box>
    </Box>
  );
};
